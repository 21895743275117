import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Alert, Button, Card, Col, Container, Modal, ModalBody, ModalFooter, Row, Table } from 'react-bootstrap';
import { FaArrowLeftLong, FaLocationArrow, FaLocationCrosshairs } from "react-icons/fa6";
import Dropdown from 'react-bootstrap/Dropdown';
import { MdEdit, MdOutlineDone } from "react-icons/md";
import { GoDotFill } from "react-icons/go";
import { BsDownload } from "react-icons/bs";
import { frontEnd_API, header, siteConfig } from '../../Config/Config';
import axios from 'axios';
import ProductPreviewImage from './Products/ProductPreviewImageComponent';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import Invoices from '../../Components/Invoices';
import Loader from '../../Components/Loader';
import { BiSolidEdit } from 'react-icons/bi';
import { Tooltip } from 'react-tooltip';
import FormComponent from '../../Components/FormComponent';
import { keyCode, pattern, shipRocketTrackLink } from '../../Config/Constant';
import Select from 'react-select'
import Swal from 'sweetalert2';
import { CiLocationArrow1 } from 'react-icons/ci';
import { Input } from 'reactstrap';
import { use } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';

function SellerSingleOrderPage() {

    const navigate = useNavigate();
    const params = useParams();
    const userDetail = useSelector((state) => state.user.value);
    console.log("userDetail :: ", userDetail, userDetail?.master)


    // state and const define -----
    const nextStatusData = [
        {
            label: 'Select Next Status',
            value: '',
            compare: ''
        },
        {
            label: 'Accepted',
            value: 'seller-accepted',
            compare: 'pending'
        },
        {
            label: 'Cancelled',
            value: 'seller-cancelled',
            compare: 'pending'
        },
        {
            label: 'Dispatched',
            value: 'dispatched',
            compare: 'ready-to-ship'
        },
        {
            label: 'In Transit',
            value: 'in-transit',
            compare: 'dispatched'
        },
        {
            label: 'Delivered',
            value: 'delivered',
            compare: 'in-transit'
        }
    ]

    const [selectedStatus, setSelectedStatus] = useState('');
    const [orderData, setOrderData] = useState();
    const [globalData, setGlobalData] = useState();
    const [cnt, setCnt] = useState(0);
    const [show, setShow] = useState(false);
    const [loader, setLoader] = useState(true);
    const moment = require('moment');

    const getSingleOrder = async () => {
        try {
            const { data } = await axios.get(`${frontEnd_API.orders}/${params.orderId}`, header)

            const status = data?.data?.orderStatus?.toLowerCase()

            setOrderData(data?.data)

            if (["pending", "seller-accepted"].includes(status)) {
                setCnt(1);
            }
            else if (["processing", "cancelled", "printed", "ready-to-ship"].includes(status)) {
                setCnt(2);
            }
            else if (["dispatched", "in-transit", "ndr"].includes(status)) {
                setCnt(3);
            }
            else if (["delivered"].includes(status)) {
                setCnt(4);
            }
            else if (["seller-cancelled", "user-cancelled"].includes(status)) {
                setCnt(0);
            }
            if (data?.status === 400) {
                window.location = '/orders'
            }
            setLoader(false);
        }
        catch (e) {
            console.log("e::", e);
            setLoader(false);
        }
    }

    const getGlobalData = async () => {
        try {
            const { data } = await axios.get(frontEnd_API?.getGlobal, header)
            console.log("data?.data::", data?.data);
            setGlobalData(data?.data)
        }
        catch (e) {
            console.log("Err::", e);
        }
    }

    useEffect(() => {
        getSingleOrder();
        getGlobalData();
    }, [])

    useEffect(() => {
        console.log("orderData::", orderData);
    }, [orderData])

    const statusBar = [
        {
            label: 'created',
            value: 1,
        },
        {
            label: 'Processing',
            value: 1,
        },
        {
            label: 'Shipped',
            value: 0,
        },
        {
            label: 'Delivered',
            value: 0,
        }
    ];

    // manage order status section start -----
    const [courierModalStatus, setCourierModalStatus] = useState(false)
    const [courierData, setCourierData] = useState([])
    const [selectedOrder, setSelectedOrder] = useState({})

    const manageOrderStatus = async () => {
        try {
            const obj = {
                orderId: orderData?.id,
                orderStatus: 'seller-accepted',
                orderCourierCompanyId: null
            }
            setSelectedOrder({ ...obj })

            if ([null, undefined, 'undefined', ''].includes(obj?.orderCourierCompanyId)) {
                const { data } = await axios?.put(frontEnd_API?.orders + '/courier/' + obj?.orderId, {}, header)
                setCourierData([...data?.data])
                setCourierModalStatus(true)
            }
        } catch (e) {
            if (![undefined, null]?.includes(e?.status)) {

            }
            else {
                const errorMessage = e?.response?.data?.message ?? "Unable to execute this action";
                if (e?.response?.data?.wallet) {
                    Swal.fire(
                        'Request failed',
                        errorMessage,
                        'error'
                    );
                    Swal.fire({
                        title: `Request failed`,
                        text: errorMessage,
                        icon: 'error',
                        showCancelButton: true,
                        confirmButtonColor: '#04ac84',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Recharge now',
                        cancelButtonText: 'No',
                        showClass: {
                            popup: 'animate__animated animate__tada'
                        },
                        // hideClass: {
                        //   popup: 'animate__animated animate__fadeOut', // Optional: fade out on close
                        // }
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            navigate(`/payment/${window.location.pathname?.replaceAll('/', '-')}`)
                        }
                    });
                }
                else {
                    Swal.fire(
                        'Request failed',
                        errorMessage,
                        'error'
                    );
                }
            }
            console.log("error :: ", e, e?.status, e?.response)
        }
    }

    // function for update return order status -----
    const handleOrderStatus = async (orderStatus = '') => {

        if (orderStatus?.length > 0) {
            try {

                const body = orderStatus == 'seller-accepted' ? {
                    orderStatus: 'seller-accepted',
                    ...selectedOrder
                } : { orderStatus }

                const { data, status } = await axios?.put(`${frontEnd_API?.orders}/status/${orderData?.id}`, { ...body }, header)
                if (status === 200) {
                    Swal.fire(
                        data?.message,
                        `Your selected order status updated with ${orderStatus?.replace(/-/g, ' ')}!`,
                        'success'
                    );
                    orderData.orderStatus = orderStatus == 'seller-accepted' ? 'ready-to-ship' : orderStatus
                    setOrderData({ ...orderData })
                    getSingleOrder()
                }
                console.log("128 sss :: ", data)
            } catch (error) {
                console.log("erroor ::: ", error)
            }
        }
    }
    // -----

    console.log("orderData::", orderData);

    document.title = `#${orderData?.orderNumber} | ${siteConfig?.name}`;
    const invoiceData = {
        invoiceTitle: "Order Invoice",
        customerName: orderData?.orderBillingName,
        customerAddress: {
            addressLine1: orderData?.orderBillingAddressLine1,
            addressLine2: orderData?.orderBillingAddressLine2,
            area: orderData?.orderBillingArea,
            city: orderData?.orderBillingCity,
            state: orderData?.orderBillingState,
            pinCode: orderData?.orderBillingPincode,
        },
        customerMobileNumber: orderData?.userData?.mobile,
        customerEmail: orderData?.userData?.email,
        customerShippingAddressState: orderData?.orderShippingCity,
        invoiceNumber: orderData?.orderNumber,
        purchaseOrderNumber: orderData?.orderId,
        orderData: orderData
    }

    // section for Switch Order to provider when its in RTN, RTO -----
    // manage provider section start -----    
    const [providerSwitchModalStatus, setProviderSwitchModalStatus] = useState(false)
    const [selectedProvider, setSelectedProvider] = useState(-1)
    const [providerListData, setProviderListData] = useState([])
    const [tempOrderObj, setTempOrderObj] = useState({})


    const getProviderForAssign = async (obj = {}) => {
        const { data, status } = await axios.get(`${frontEnd_API.orders}/provider/${orderData?.id}/switch`, header)
        if (status == 200) {
            if (data?.data?.length <= 0) {
                Swal.fire(
                    `No Provider available for selected order!`,
                    "",
                    'warning'
                );
                return
            }

            setTempOrderObj({
                orderId: orderData?.id,
                orderNumber: orderData?.orderNumber,
                orderNetAmount: orderData?.orderNetAmount,
            })
            setSelectedProvider(-1)
            setProviderSwitchModalStatus(true)
            setProviderListData([...data?.data])
        }
    }

    const updateProviderForOrder = async () => {

        try {
            const body = {
                providerId: selectedProvider,
                providerBusinessId: providerListData?.filter(e => e?.providerId == selectedProvider)[0]?.productData[0]?.providerBusinessId,
                productData: providerListData?.filter(e => e?.providerId == selectedProvider)[0]?.productData,
                from: 'switch'
            }
            console.log("body ::: ", body, tempOrderObj)
            // return
            const { data, status } = await axios.post(`${frontEnd_API.orders}/provider/${tempOrderObj?.orderId}`, body, header)
            if (status == 200) {
                Swal.fire(`Order successfully assigned to provider.`, ``, 'success');
                setSelectedProvider(-1)
                setTempOrderObj({})
                setProviderSwitchModalStatus(false)
                getSingleOrder()
            }

        } catch (e) {
            if (![undefined, null]?.includes(e?.status)) {

            }
            else {
                const errorMessage = e?.response?.data?.message ?? "Unable to execute this action";
                if (e?.response?.data?.wallet) {
                    Swal.fire(
                        'Request failed',
                        errorMessage,
                        'error'
                    );
                    Swal.fire({
                        title: `Request failed`,
                        text: errorMessage,
                        icon: 'error',
                        showCancelButton: true,
                        confirmButtonColor: '#04ac84',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Recharge now',
                        cancelButtonText: 'No',
                        showClass: {
                            popup: 'animate__animated animate__tada'
                        },
                        // hideClass: {
                        //   popup: 'animate__animated animate__fadeOut', // Optional: fade out on close
                        // }
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            navigate(`/payment/${window.location.pathname?.replaceAll('/', '-')}`)
                        }
                    });
                }
                else {
                    Swal.fire(
                        'Request failed',
                        errorMessage,
                        'error'
                    );
                }
            }
            console.log("error :: ", e, e?.status, e?.response)
        }
    }
    // -----




    return (
        <div className='py-2 custom-single-order-container'>
            <Container className='custom-single-order py-4'>
                <div className='d-grid'>
                    <div className='d-flex'>
                        <Link
                            to={(params?.groupStatus && params?.orderStatus) ? `/orders/${params?.groupStatus}/${params?.orderStatus}` : `/orders`} className='custom-single-order-back-link'><FaArrowLeftLong />Back</Link>
                    </div>
                    <div className='d-grid gap-2 gap-lg-0 d-sm-flex justify-content-sm-between align-items-center pt-3'>
                        <div className='d-grid'>
                            <span className='h3 fw-semibold m-0 fs-sm-20 first-capitalize'>{(orderData?.orderType.toLowerCase() == "customer") ? "" : orderData?.orderType?.toLowerCase()} Order #{orderData?.orderNumber}</span>
                            <span className='p-color fs-14 fs-sm-12'>{moment(orderData?.orderDate).format('MMM D, YYYY h:mm A')}</span>
                        </div>
                        <div className='d-flex gap-2 custom-order-header-btn justify-content-sm-end'>
                            {/* <PDFDownloadLink
                                document={<Invoices data={invoiceData} />}
                                fileName="myDocument.pdf"
                            >
                                {({ blob, url, loading, error }) =>
                                    loading ? 'Loading document...' : <span className='flex-center-align gap-2 fs-14'><BsDownload className='me-1' />Download Invoice</span>
                                }
                            </PDFDownloadLink> */}
                            {
                                ['delivered']?.includes(orderData?.orderStatus) && (
                                    <Link
                                        to={orderData?.orderInvoiceLink}
                                        target='_blank'
                                        className='text-dark px-3 py-2 fw-semibold text-decoration-none d-flex align-items-center gap-2 rounded-2'
                                    >
                                        <BsDownload className='text-dark' />
                                        <span>Download Invoice</span>
                                    </Link>
                                )
                            }
                        </div>
                    </div>
                </div>

                {
                    loader ?
                        <div className='d-grid align-content-center' style={{ minHeight: "80vh" }}>
                            <div className='w-100 aspect-4-1 aspect-md-2-1'>
                                <Loader />
                            </div>
                        </div>
                        : <Row className='pt-3'>
                            <Col className='col-12 col-md-8 d-grid gap-3 align-content-start' >

                                <div className='d-grid seller-box p-3 p-lg-4 gap-3'>

                                    {/* Order Status Bar */}
                                    {/*
                                    <div className={`d-block mt-2 d-sm-flex flex-between-align custom-order-status-bar mb-sm-3 ${(cnt <= 0) ? "d-flex w-100" : ""}`}>
                                        {
                                            (cnt > 0) ?
                                                statusBar.map((item, index) => {
                                                    return (
                                                        <div key={index}
                                                            className={`w-100 d-flex align-items-center 
                                                                custom-order-status-bar-div gap-2 fw-semibold fs-14 
                                                    ${(cnt > index) ? 'custom-bg-info text-light ' : ''}
                                                    ${((cnt - 1) == index) ? 'last-child-style' : ''}`}
                                                        >
                                                            {(cnt > index)
                                                                ? < MdOutlineDone />
                                                                : <GoDotFill />
                                                            }
                                                            <span className='first-capitalize'>{item.label}</span>
                                                            <section className='fw-meduim fs-12 fs-sm-10'>{item.date}</section>
                                                        </div>
                                                    )
                                                })
                                                : (cnt == 0) ?
                                                    <div className={`w-100 align-items-center 
                                                        custom-order-status-bar-div gap-2 fw-semibold fs-14 custom-bg-info bg-danger text-center text-light first-capitalize `} style={{ borderRadius: "2rem" }}>
                                                        {(orderData?.orderStatus) && orderData?.orderStatus.replace("-", " ")}
                                                    </div>
                                                    : (cnt == -1) ?
                                                        <div className={`w-100 align-items-center 
                                                            custom-order-status-bar-div gap-2 fw-semibold fs-14 custom-bg-info bg-danger text-center text-light first-capitalize `} style={{ borderRadius: "2rem" }}>
                                                            {(orderData?.orderStatus) && orderData?.orderStatus.replace("-", " ")}
                                                        </div>
                                                        : (cnt == -2) &&
                                                        <div className={`w-100 align-items-center 
                                                                custom-order-status-bar-div gap-2 fw-semibold fs-14 custom-bg-info bg-danger text-center text-light first-capitalize `} style={{ borderRadius: "2rem" }}>
                                                            {['NDR', 'ndr']?.includes(orderData?.orderStatus) ? 'Order in NDR mode' : 'Tracking data not found. Please try again later.'}
                                                        </div>
                                        }
                                    </div>
                                    */}
                                    {
                                        ["user-cancelled"]?.includes(orderData?.orderStatus) && (
                                            <div className='d-flex flex-column'>
                                                <span>Reason: <b className='first-capitalize fs-12'>{orderData?.orderCancelReason?.replace(/-/g, ' ')}</b></span>
                                                <span className='description small first-cap'>{orderData?.orderCancelDescription}</span>
                                            </div>
                                        )
                                    }
                                    {/* Price List */}
                                    <div className='d-grid gap-1 gap-sm-2'>
                                        <div className='custom-price-list-table'>
                                            <div>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th className='fs-14 text-center'>Image</th>
                                                            <th className='fs-14 text-center'>Product name</th>
                                                            <th className='fs-14 text-center'>Qty</th>
                                                            <th className='fs-14 text-center'>Price</th>
                                                            <th className='fs-14 text-center'>Total</th>
                                                            <th className='fs-14 text-center'>Discount</th>
                                                            <th className='fs-14 text-center'>Sub Total</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            (orderData?.orderChildData?.length > 0) &&
                                                            orderData?.orderChildData.map((row, pIndex) => {
                                                                // console.log("row::", row);
                                                                const colorCode = row?.childOrderVariantData?.filter(e => ['color', 'colors', 'colour', 'colours']?.includes(e?.parentName))?.map(e => e?.variantCode)

                                                                return (
                                                                    <tr className='fs-14'>
                                                                        <td className='text-center p-0 m-0' style={{ width: "80px" }}>
                                                                            <div className='d-flex flex-column w-100 justify-content-center align-items-center pt-1'>
                                                                                <div className='custom-order-product-img border-card'>
                                                                                    <ProductPreviewImage
                                                                                        colorCode={colorCode ?? '#ffffff'}
                                                                                        isButtonShow={false}
                                                                                        customScale={10}
                                                                                        objectData={{ preview: row?.productThumbnail || null }} />
                                                                                </div>
                                                                                {
                                                                                    row?.returnOrderChildId > 0 && (<span className='text-danger fw-semibold'>RTN</span>)
                                                                                }
                                                                                {
                                                                                    (row?.relatedOrderChildId > 0 && row?.relatedWithRTS == 0) && <span className='text-danger fw-semibold'>RTO</span>
                                                                                }
                                                                                {
                                                                                    row?.relatedWithRTS == 1 && <span className='text-danger fw-semibold'>RTS</span>
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                        <td className='ps-2 first-capitalize'>
                                                                            <div className="d-grid gap-1 align-content-center">
                                                                                {/* <span>{row?.productName.substring(0, 18) + '...'}</span> */}
                                                                                <span>{row?.productName}</span>
                                                                                <div className='d-flex flex-wrap gap-2'>
                                                                                    {
                                                                                        row?.childOrderVariantData?.length > 0 &&
                                                                                        row?.childOrderVariantData.map((variant, varIndex) => (
                                                                                            <span key={varIndex} className='first-capitalize fs-12 fs-sm-12'>{variant?.parentName} : <span className='text-uppercase'>{variant?.variantName}</span></span>
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className='text-center'>{row?.productQuantity}</td>
                                                                        <td className='text-center'>{row?.productPrice}</td>
                                                                        <td className='text-center'>{row?.productSubTotal}</td>
                                                                        <td className='text-center'>{Number(row?.productDiscount) + Number(row?.productCouponAmount)}</td>
                                                                        <td className='text-center fw-semibold'>{keyCode?.inr}{Number(row?.productTotal)}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <th className='fs-16 text-center' colSpan={6}>Product Total</th>
                                                            <th className='fs-16 text-center'>{keyCode?.inr}{Number(orderData?.orderNetAmount)}</th>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Order Main Tracking */}

                                <div className='d-grid seller-box p-3 p-lg-4 gap-2'>
                                    <h5 className='my-2 fw-semibold color-temp d-flex align-items-center gap-3'><FaLocationCrosshairs /> Order Summary :</h5>
                                    <div className='d-grid timeline'>
                                        {
                                            (orderData?.orderTrackingData?.length > 0) &&
                                            orderData?.orderTrackingData?.map((item, index) => {
                                                return (
                                                    <div key={index} className={`timeline-item ${((orderData?.orderTrackingData?.length - 1) === index) ? 'lastItem' : ''}`}>
                                                        <div
                                                            className={`timeline-icon circle-one mt-1 ms-1`}
                                                            style={{
                                                                backgroundColor: (index === 0) ? 'darkblue' : 'grey',
                                                                outlineColor: (index === 0) ? 'darkblue' : 'grey',
                                                            }}
                                                        ></div>
                                                        <div className='timeline-content'>
                                                            <span className='timestamp'>{moment(item?.updatedAt).format('MMM D, YYYY h:mm A')}</span>
                                                            <div>
                                                                <b className='first-capitalize'>{item?.trackingStatus}</b>
                                                                <span className='description'>{item?.trackingRemark}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </Col>
                            <Col className='col-12 col-md-4 gap-2 pt-4 pt-md-0 '>
                                <div className='position-sticky ' style={{ top: "25px" }}>
                                    {
                                        (orderData?.orderCourierCompanyId > 0) &&
                                        <div className='d-grid mb-4 seller-box align-content-start' style={{ overflow: 'hidden' }}>
                                            <div className='flex-between-align p-3 border-bottom'>
                                                <h5 className='fw-semibold m-0 fs-lg-16'>Logistics Details</h5>
                                                {
                                                    (orderData?.shipRocketOrderId) > 0 && (
                                                        <Link
                                                            target='_blank'
                                                            to={shipRocketTrackLink?.replace('{{AWb_CODE}}', orderData?.shipRocketOrderTrackingDetail?.awb_code)}
                                                            className='fw-semibold bg-success text-white px-2 py-1 rounded text-decoration-none'
                                                        >
                                                            <FaLocationArrow />&nbsp;Track
                                                        </Link>
                                                    )
                                                    // <Button variant='success' className='fs-12 fs-sm-10 flex-between-align gap-2'></Button>
                                                }
                                            </div>
                                            <div className='d-grid p-3'>
                                                <div className='w-100 aspect-3-1'>
                                                    <img src={require('../../Assets/Gif/truck.c55607e57cfb92d8eca1.gif')} className='w-100 h-100' style={{ objectFit: "contain" }} alt="" />
                                                </div>
                                                <h6 className='fw-semibold m-0 fs-lg-16 text-center'>{orderData?.orderCourierDetail?.courierName}</h6>
                                            </div>
                                            {(orderData?.shipRocketOrderId) > 0 &&
                                                <div className='d-grid p-3'>
                                                    <section className='d-grid gap-2'>
                                                        <div className='d-grid d-flex justify-content-between gap-2 fs-12'>
                                                            <div className="d-grid">
                                                                <span>Shipping Order ID</span>
                                                                <span className='fw-semibold'>{orderData?.shipRocketOrderId}</span>
                                                            </div>
                                                            <div className="d-grid">
                                                                <span className='text-end'>Shipment ID</span>
                                                                <span className='fw-semibold text-end'>{orderData?.shipRocketShipmentId}</span>
                                                            </div>
                                                        </div>
                                                        <div className='d-grid d-flex justify-content-between gap-2 fs-12'>
                                                            <div className="d-grid">
                                                                <span>Routine Code</span>
                                                                <span className='fw-semibold'>{orderData?.shipRocketOrderTrackingDetail?.routing_code}</span>
                                                            </div>
                                                            <div className="d-grid">
                                                                <span className='text-end'>Pickup Date</span>
                                                                <span className='fw-semibold text-end'>{orderData?.shipRocketOrderTrackingDetail?.pickup_scheduled_date ? moment(orderData?.shipRocketOrderTrackingDetail?.pickup_scheduled_date).format('MMM D, YYYY h:mm A') : "-"}</span>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </div>}
                                        </div>
                                    }
                                    {
                                        orderData?.shipRocketOrderId > 0 && (
                                            <div className='d-grid seller-box align-content-start mb-4'>
                                                <div className='flex-between-align p-3 border-bottom'>
                                                    <h5 className='fw-semibold m-0 fs-lg-16'>Label and manifest</h5>
                                                </div>
                                                <div className='d-grid p-3 gap-2'>
                                                    <Link
                                                        className='badge bg-warning py-2 px-2 fs-13 first-capitalize text-dark text-decoration-none'
                                                        to={orderData?.shipRocketLabelUrl}
                                                    >
                                                        Download Label
                                                    </Link>
                                                    <Link
                                                        className='badge bg-info py-2 px-2 fs-13 first-capitalize text-white text-decoration-none'
                                                        to={orderData?.shipRocketManifestUrl}
                                                    >
                                                        Download Manifest
                                                    </Link>
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        orderData?.providerBusinessId <= 0 && (
                                            <>
                                                {
                                                    ['pending']?.includes(orderData?.orderStatus?.trim()?.toLowerCase()) && (
                                                        <div className='d-grid seller-box align-content-start mb-4'>
                                                            <div className='p-3'>
                                                                If you don't want to use product from your RTN or RTO stock, select a provider to fulfill your order.
                                                            </div>
                                                            <div className='d-grid p-3 gap-2'>
                                                                <Alert
                                                                    className='m-0 text-center fw-semibold pb-1 fs-lg-16 fs-18 first-capitalize py-1 px-2 px-sm-3 pointer'
                                                                    variant={"info"}
                                                                    onClick={() => getProviderForAssign()}
                                                                >
                                                                    Get Providers
                                                                </Alert>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    userDetail?.master && (
                                                        <div className='d-grid seller-box align-content-start mb-4'>
                                                            <div className='flex-between-align p-3 border-bottom'>
                                                                <h5 className='fw-semibold m-0 fs-lg-16'>Update Status</h5>
                                                            </div>
                                                            <div className='d-grid p-3 gap-2'>
                                                                {
                                                                    !['delivered']?.includes(orderData?.orderStatus) && (
                                                                        <>
                                                                            <div className='d-flex gap-2'>
                                                                                <Select
                                                                                    options={nextStatusData?.filter(e => ['', null, orderData?.orderStatus]?.includes(e?.compare))}
                                                                                    value={nextStatusData?.filter(e => e?.value == selectedStatus)}
                                                                                    onChange={(e) => {

                                                                                        if (e?.value == 'seller-accepted') {
                                                                                            manageOrderStatus()
                                                                                        } else {
                                                                                            setSelectedStatus(e?.value)
                                                                                            handleOrderStatus(e?.value)
                                                                                        }

                                                                                    }}
                                                                                    className='w-100'
                                                                                />
                                                                            </div>
                                                                            <hr className='my-1 mt-2' />
                                                                        </>
                                                                    )
                                                                }
                                                                <Alert
                                                                    className='m-0 text-center fw-semibold pb-1 fs-lg-16 fs-18 first-capitalize py-1 px-2 px-sm-3'
                                                                    variant={"danger"}
                                                                >
                                                                    {orderData?.orderStatus?.replace(/-/g, ' ')?.toLowerCase()}
                                                                </Alert>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    }

                                    <div className='d-grid seller-box align-content-start' style={{ overflow: 'hidden' }}>
                                        <div className='flex-between-align p-3 border-bottom'>
                                            <h5 className='fw-semibold m-0 fs-lg-16'>Customer</h5>
                                            {/* <Link className='fs-18 fs-lg-14 link-to-normal flex-between-align gap-2'><MdEdit />Edit</Link> */}
                                        </div>
                                        <div className='d-grid p-3 border-bottom'>
                                            <b className='fw-semibold fs-lg-16 fs-18 pb-1'>Personal details</b>
                                            <span className='fs-lg-12 fs-14 d-flex gap-1 fw-semibold'>
                                                <i className="bi bi-person-circle"></i>
                                                {orderData?.userData?.name}
                                            </span>
                                            <span className='fs-lg-12 fs-14 d-flex gap-1'>
                                                <i className="bi bi-envelope"></i>
                                                {orderData?.userData?.email}
                                            </span>
                                            <span className='fs-lg-12 fs-14 d-flex gap-1'>
                                                <i className="bi bi-telephone-fill"></i>
                                                {orderData?.userData?.mobile}
                                            </span>
                                        </div>
                                        <div className='d-grid p-3'>
                                            <section className='flex-between-align'>
                                                <b className='fw-semibold pb-1 fs-lg-16 fs-18'>Shipping address</b>
                                                {
                                                    ["pending"]?.includes(orderData?.orderStatus) && (
                                                        <>
                                                            <Button
                                                                variant='success'
                                                                className='py-1 px-3 fs-14'
                                                                data-tooltip-id="my-info"
                                                                data-tooltip-content="Edit address"
                                                                onClick={() => setShow(true)}
                                                            >
                                                                <BiSolidEdit />
                                                            </Button>
                                                            <Tooltip id="my-info" place="top" />
                                                        </>
                                                    )
                                                }
                                            </section>
                                            <p className='m-0 fs-lg-12 fs-14 p-color d-flex flex-wrap first-capitalize'>
                                                {orderData?.orderShippingAddressLine1}, {orderData?.orderShippingAddressLine2}, {orderData?.orderShippingArea}, {orderData?.orderShippingCity}, {orderData?.orderShippingState}, {orderData?.orderShippingCountry} - {orderData?.orderShippingPincode}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='mt-4 d-grid seller-box align-content-start' style={{ overflow: 'hidden' }}>
                                        <div className='flex-between-align p-3 border-bottom'>
                                            <h5 className='fw-semibold m-0 fs-lg-16'>Billing</h5>
                                            {/* <Link className='fs-16 fs-lg-14 fw-semibold link-to-normal seller-box flex-between-align  px-lg-3 py-lg-1 px-md-2 px-3 py-1 gap-2'><BsDownload />Download</Link> */}
                                        </div>
                                        <div className='flex-between-align p-3 border-bottom'>
                                            <span className='fs-lg-14'>Sub total</span>
                                            <b className='fs-lg-14 text-success'>{keyCode?.inr}{orderData?.orderSubTotalAmount}</b>
                                        </div>
                                        <div className='d-grid gap-1 p-3 border-bottom'>
                                            <div className='flex-between-align'>
                                                <span className='fs-lg-14'>Discount</span>
                                                <b className='fs-lg-14'>- {keyCode?.inr}{orderData?.orderCouponAmount}</b>
                                            </div>
                                        </div>
                                        {
                                            orderData?.orderType?.toLowerCase()?.trim() == 'zoho' && (
                                                <div className='d-grid gap-1 p-3 border-bottom'>
                                                    <div className='flex-between-align'>
                                                        <span className='fs-lg-14'>Total Shipping cost</span>
                                                        <b className='fs-lg-14 text-danger'>+ {keyCode?.inr}{orderData?.orderShippingChargeAmount}</b>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        <div className='flex-between-align p-3'>
                                            <b className='fs-lg-14'>Total costs</b>
                                            <b className='color-info fs-lg-16 fs-18'>{keyCode?.inr}{orderData?.orderNetAmount}</b>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                }
            </Container>

            {/* address change modal start */}
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={() => setShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="text-center">
                        Update shipping address
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormComponent
                        valueData={{
                            orderShippingName: orderData?.orderShippingName,
                            orderShippingAddressLine1: orderData?.orderShippingAddressLine1,
                            orderShippingAddressLine2: orderData?.orderShippingAddressLine2,
                            orderShippingArea: orderData?.orderShippingArea,
                            orderShippingCity: orderData?.orderShippingCity,
                            orderShippingState: orderData?.orderShippingState,
                            orderShippingPincode: orderData?.orderShippingPincode
                        }}
                        action={frontEnd_API.orders}
                        actionType={`put`}
                        actionValue={params?.orderId}
                        submitFullWidth={true}
                        formMessage={"Address changed successfully!"}
                        onSubmit={(e) => {
                            console.log("data from form::", e);
                            setShow(false);
                            setOrderData((prevData) => ({
                                ...prevData,
                                ...e?.data
                            }))
                        }}
                        fields={[
                            {
                                divClass: 'col-12 col-md-6',
                                name: 'Name',
                                key: 'orderShippingName',
                                required: true,
                                placeholder: "Enter coupon name"
                            },
                            {
                                divClass: 'col-12 col-md-6',
                                name: 'Address Line 1',
                                key: 'orderShippingAddressLine1',
                                required: true,
                                placeholder: "Enter amount or percentager value"
                            },
                            {
                                divClass: 'col-12 col-md-6',
                                name: 'Address Line 2',
                                key: 'orderShippingAddressLine2',
                                required: true,
                                placeholder: "Enter amount or percentager value"
                            },
                            {
                                divClass: 'col-12 col-md-6',
                                name: 'Area',
                                key: 'orderShippingArea',
                                required: true,
                                placeholder: "Enter limit condition"
                            },
                            {
                                type: 'select',
                                options: (globalData) && globalData,
                                divClass: 'col-12 col-md-4',
                                name: 'orderShippingState',
                                label: "State",
                                key: 'orderShippingState',
                                required: true,
                                onChange: {
                                    from: 'children',
                                    to: 'orderShippingCity',
                                    options: (globalData) && globalData
                                }
                            },
                            {
                                divClass: 'col-12 col-md-4',
                                type: 'select',
                                name: 'orderShippingCity',
                                label: "City",
                                key: 'orderShippingCity',
                                required: true,
                                options: globalData?.filter(item => item.value === orderData?.orderShippingState)[0]?.children
                            },
                            {
                                divClass: 'col-12 col-md-4',
                                name: 'Pincode',
                                key: 'orderShippingPincode',
                                required: true,
                                pattern: pattern?.pinCode
                            },
                        ]}
                    />
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button onClick={() => setShow(false)}>Close</Button>
                </Modal.Footer> */}
            </Modal>
            {/* address change modal start */}

            {/* courier list modal start */}
            <Modal
                show={courierModalStatus}
                onHide={() => setCourierModalStatus(!courierModalStatus)}
                aria-labelledby="contained-modal-title-vcenter"
                size="md"
                style={{ maxHeight: '75vh' }}
                centered
                scrollable
            >
                <Modal.Header className='d-flex flex-column'>
                    <Modal.Title className='text-center w-100 fs-sm-14'>Select Courier</Modal.Title>
                    <div className='d-flex justify-content-between align-items-center w-100'>
                        <div className='d-flex flex-column jusity-content-start align-items-start'>
                            <label className='fs-12 fw-bold text-secondary'>Order Number</label>
                            <div className='fs-12 fw-bold text-success'>{orderData?.orderNumber}</div>
                        </div>
                        <div className='d-flex flex-column jusity-content-end align-items-start'>
                            <div className='d-flex flex-column jusity-content-end align-items-end'>
                                <label className='fs-12 fw-bold text-secondary'>Order Date</label>
                                <div className='fs-12 fw-bold text-success'>{orderData?.orderDate}</div>
                            </div>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className='custom-modal-radio-select'>
                    <div className="custom-radio-group px-0">
                        {
                            courierData?.map((row, index) => <>
                                <Card
                                    className={`pointer border ${selectedOrder?.orderCourierCompanyId == row?.id ? 'border-2 border-success' : 'border-secondary'} rounded-2 py-1 px-2 position-relative`}
                                    onClick={() => {
                                        selectedOrder.orderCourierCompanyId = row?.id
                                        selectedOrder.shippingCharge = row?.rate
                                        selectedOrder.orderCourierDetail = row
                                        setSelectedOrder({ ...selectedOrder })
                                    }}
                                >
                                    <label className='position-absolute top-0 start-0 translate-middle'>
                                        <Input
                                            type='radio'
                                            className={selectedOrder?.orderCourierCompanyId != row?.id ? 'd-none' : ''}
                                            checked={selectedOrder?.orderCourierCompanyId == row?.id}
                                        />
                                    </label>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div className='fs-14 fw-bold'>{row?.courierName}</div>
                                        <div className='d-flex gap-3'>
                                            {
                                                row?.codCharges > 0 && (
                                                    <div className='d-flex flex-column align-items-end text-secondary'>
                                                        <div className='fs-12 fw-bold'>{keyCode?.inr}{row?.codCharges}</div>
                                                        <div className='fs-10 fw-semibold'>COD Charge</div>
                                                    </div>
                                                )
                                            }
                                            <div className='d-flex flex-column align-items-end text-secondary'>
                                                <>
                                                    <div className='fs-12 fw-bold'>{keyCode?.inr}{row?.rate}</div>
                                                    <div className='fs-10 fw-semibold'>Courier charge</div>
                                                </>
                                            </div>
                                            <div className='d-flex flex-column align-items-end text-secondary'>
                                                <>
                                                    <div className='fs-12 fw-bold'>{row?.rating}</div>
                                                    <div className='fs-10 fw-semibold'>Rating</div>
                                                </>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </>)
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer className='flex-center-align'>
                    <Button
                        variant="success"
                        onClick={() => {
                            if (selectedOrder?.orderCourierCompanyId > 0) handleOrderStatus('seller-accepted')
                        }}
                        disabled={selectedOrder?.orderCourierCompanyId < 1}
                        className='first-capitalize'
                    >
                        Click here to confirm
                    </Button>
                    <Button variant="secondary" onClick={() => setCourierModalStatus(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
            {/* courier list modal close */}


            {/* Modal for Change Provider start */}
            <Modal
                show={providerSwitchModalStatus}
                onHide={() => setProviderSwitchModalStatus(!providerSwitchModalStatus)}
                aria-labelledby="contained-modal-title-vcenter"
                size="lg"
                style={{
                    maxHeight: '75vh'
                }}
                centered
                scrollable
            >
                <Modal.Header className='d-flex flex-column'>
                    <Modal.Title className='text-center w-100 fs-sm-14'>Switch Provider</Modal.Title>
                    <div className='d-flex justify-content-between align-items-center w-100 my-2'>
                        <span className='border border-2 border-secondary rounded-1 px-2 py-1 fs-13 fw-semibold'>Order ID #{tempOrderObj?.orderNumber}</span>
                        <span className='border border-2 border-secondary rounded-1 px-2 py-1 fs-13 fw-semibold'>{keyCode?.inr}{tempOrderObj?.orderNetAmount}</span>
                    </div>
                </Modal.Header>
                <ModalBody>
                    <div className='d-flex flex-column gap-1'>
                        {
                            providerListData?.map((row, index) => <>
                                <div
                                    key={index}
                                    className={`border border-success rounded-3 px-2 py-1 position-relative pointer ${row?.providerId == selectedProvider ? 'border-2' : ''}`}
                                    onClick={() => {
                                        tempOrderObj.orderCourierCompanyId = 0
                                        setTempOrderObj({ ...tempOrderObj })
                                        setSelectedProvider(row?.providerId)
                                    }}
                                >
                                    <div className='fw-semibold text-capitalize fs-15 mb-1'>Provider name:&nbsp;{row?.providerName}</div>
                                    <Table responsive className='align-middle table-nowrap table table-hover border border-dashed border-end-0 border-start-0'>
                                        <thead>
                                            <tr>
                                                <th className='text-secondary fs-13'>Sr No.</th>
                                                {/* <th className='text-secondary fs-13'>Image</th> */}
                                                <th className='text-secondary fs-13'>Name</th>
                                                <th className='text-secondary fs-13'>Qty</th>
                                                <th className='text-secondary fs-13'>Product Price.</th>
                                                <th className='text-secondary fs-13'>Design Charge</th>
                                                <th className='text-secondary fs-13'>Total Payble</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                row?.productData?.map((childRow, childKey) => <>
                                                    <tr key={childKey} className='fs-13'>
                                                        <td>{childKey + 1}</td>
                                                        <td>
                                                            <div className='d-flex flex-column gap-1'>
                                                                <span>{childRow?.productName}</span>
                                                                <div className='d-flex gap-1'>
                                                                    {
                                                                        childRow?.variantData?.map(e => <span className='badge bg-success fs-11 fw-medium text-capitalize'>{e?.parent}:&nbsp;{e?.label}</span>)
                                                                    }
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>{childRow?.productQuantity}</td>
                                                        <td>{keyCode?.inr}{Number(childRow?.totalPrice / childRow?.productQuantity).toFixed(2)}</td>
                                                        <td>{keyCode?.inr}{Number(childRow?.designCharge / childRow?.productQuantity).toFixed(2)}</td>
                                                        <td>{keyCode?.inr}{Number(childRow?.totalPayble).toFixed(2)}</td>
                                                    </tr>
                                                </>)
                                            }
                                        </tbody>
                                    </Table>
                                    {row?.providerId == selectedProvider && <div className='top-0 end-0 position-absolute pe-2'>
                                        <Input type="checkbox" checked className='bg-success border-0' />
                                    </div>}
                                </div>
                            </>)
                        }
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className='d-flex justify-content-start gap-2 align-items-center'>
                        <Button
                            color='success'
                            className='bg-success border-0'
                            disabled={selectedProvider <= 0}
                            onClick={() => updateProviderForOrder()}
                        >Appy and save</Button>
                        <Button
                            color='danger'
                            className='bg-danger border-0'
                            onClick={() => {
                                setSelectedProvider(-1)
                                setProviderSwitchModalStatus(false)
                            }}
                        >Close</Button>
                    </div>
                </ModalFooter>
            </Modal>
            {/* Modal for Change Provider close */}

            <ToastContainer />
        </div>
    )
}

export default SellerSingleOrderPage