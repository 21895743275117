import React, { useEffect, useState } from 'react'
import { BASE_URL, frontEnd_API, header, siteConfig, storage } from '../../Config/Config';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { GiNotebook } from 'react-icons/gi';
import { TbFileInvoice } from 'react-icons/tb';
import moment from 'moment';
import { Input } from 'reactstrap';
import ProductPreviewImage from './Products/ProductPreviewImageComponent';
import { FaTrash } from 'react-icons/fa6';
import { Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { keyCode, shortCode } from '../../Config/Constant';

const RtoOrderList = ({ status = 'all', orderStatus = 'all'}) => {

    const navigate = useNavigate()
    
    // state and define -----
    const rtoOrderStatusData = [
        {
            label:'all',
            value:'all',
            class:'bg-success text-white'
        },
        {
            label:'RTO Initiated',
            value:'rto-initiated',
            class:'bg-warning'
        },
        {
            label:'RTO In Transit',
            value:'rto-in-transit',
            class:'bg-info'
        },
        {
            label:'RTO Delivered',
            value:'rto-delivered',
            class:'bg-dark text-white'
        },
    ]
    const [checkAll, setCheckAll] = useState(false)
    const [rtoOrderDataStatus, setRtoOrderDataStatus] = useState(-1)
    const [filterObj, setFilterObj] = useState({
        page:1,
        limit:10,
        search:'',
        orderStatus,
        dateRange:[]
    })
    const [rtoOrderData, setRtoOrderData] = useState([])
    // -----

    // function for get rto orders -----
    const getRtoOrders = async () => {
        try {
            const { data, status } = await axios?.put(`${frontEnd_API?.rtoOrder}`, filterObj, header);
            setTimeout(() => {
                setRtoOrderData(status == 200 ? data?.data : [])
                setRtoOrderDataStatus(status == 200 ? 1 : rtoOrderDataStatus)
            }, 1000)
        } catch (error) {
            setRtoOrderDataStatus(0)
            setRtoOrderData([])
        }
    }
    // -----


    // useEffect to get rto order detal -----
    useEffect(() => {
        setRtoOrderData([])
        setRtoOrderDataStatus(-1)
        setFilterObj({
            ...filterObj,
            orderStatus,
        })
    }, [status, orderStatus])

    useEffect(() => {
        getRtoOrders()
    }, [filterObj])
    // -----

    // function for manage all rows check box -----
    const manageCheckBox = async (checked = false) => {
        const data = rtoOrderData?.filter(e => e?.productQuantity > e?.usedQuantity)?.map(e => ({
            ...e,
            checked
        }))
        if (checked && data?.length <= 0) {
            Swal.fire(
                'Failed',
                'No products available for re-order or create order',
                'error'
            )
            setCheckAll(false)
            return
        }
        setCheckAll(checked)
        setRtoOrderData([...data])
    }
    // -----


    // function for create component for action buttons -----
    const OrderActionButton = ({ row }) => {
        return (
            <div className='custom-order-dropdown d-flex align-items-center gap-3'>
                <Link
                    data-tooltip-id="my-tooltip-edit"
                    data-tooltip-content="More Details"
                    variant='success'
                    className='more-btn'
                    to={`/returns/rto/${orderStatus}/${row?.orderAnotherNumber}`}
                    state={{ data: row }}
                >
                    <TbFileInvoice className='fs-18' />
                </Link>
            </div>
        )
    }
    // -----

    const deleteRow = async (id = 0) => {
        if (id > 0) {
            const response = await Swal.fire({
                title: `Are you sure to delete this RTO product?`,
                text: `This action can't be reversed`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#04ac84',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm',
                cancelButtonText: 'No',
            });
    
            if (response?.isConfirmed) {
                const { data, status } = await axios?.delete(`${frontEnd_API?.rtoOrder}/${id}`, header);
                if (status == 200) {
                    Swal.fire( 'Deleted', `${data?.message}`, 'success');
                    getRtoOrders()
                }
            }
        }
    }

    document.title = `Seller Return Orders | ${siteConfig?.name}`;

    return (
        <>
            <div className='pb-2 d-grid gap-3 custom-btn-scrollbar'>
                <div className='d-flex pt-2 pt-sm-3 overflow-hidden'>
                    <div className='main-custom-order-sub-category'>
                        <div className='d-flex custom-order-sub-category pointer text-capitalize fs-14 fs-md-12 fs-sm-10'>
                        {
                            rtoOrderStatusData?.map((row, index) => (
                                <span
                                    key={index}
                                    className={`fw-semibold text-center px-2 px-sm-3 py-1 text-black ${row?.value == orderStatus ? row?.class : ''}`}
                                    title={row?.label}
                                    onClick={() => navigate(`/returns/${status}/${row?.value}`)}
                                >
                                    {row?.label}
                                </span>
                            ))
                        }
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-center gap-2">
                {
                    rtoOrderData?.filter(e => e?.checked)?.length > 0 && (
                        <div
                            className='fw-semibold fs-14 text-success border-success p-2 rounded-2 text-nowrap pointer border border-2 mx-2'
                            onClick={() => {
                                const cartData = JSON.parse(localStorage?.getItem(storage?.cart)?? '[]')
                                let existRTOIdsData = cartData?.filter(e => e?.from == shortCode?.RTO)?.map(e => Number(e?.relatedOrderChildId))
                                let data = rtoOrderData?.filter(e => e?.checked)?.filter(e => !existRTOIdsData?.includes(Number(e?.id)))?.map(e => ({
                                    from:shortCode?.RTO,
                                    productId:e?.productId,
                                    currentQuantity:e?.productQuantity - e?.usedQuantity,
                                    quantity:e?.productQuantity - e?.usedQuantity,
                                    productPrice:parseFloat(e?.productPrice),
                                    productVariantData:e?.variantData,
                                    variantData:e?.variantData?.reduce((acc, row) => {
                                        acc[row.variantParentName] = row.variantId;
                                        return acc;
                                      }, {}),
                                    relatedOrderChildId:e?.id,
                                    relatedOrderId:e?.orderId,
                                }))
                                
                                if (data?.length <= 0) {
                                    Swal.fire(
                                        'Failed',
                                        'Selected products already added into your cart from RTO Orders',
                                        'error'
                                    )
                                    return
                                }                                
                                localStorage?.setItem(storage?.cart, JSON.stringify([...cartData, ...data]))
                                window.location = `${BASE_URL}create-orders`
                            }}
                        >
                            Create order
                        </div>
                    )   
                }
                    <div class="custom-seller-category-search px-0 container-fluid">
                        <div class="d-flex align-items-center gap-2 gap-lg-3 px-2 px-lg-3">
                            <label class="bi bi-search" for="text"></label>
                            <input
                                type="text"
                                class="py-2 fs-sm-14 pe-2"
                                id="text"
                                placeholder="Search by rto order ID"
                                autocomplete="off"
                                onChange={(e) => {
                                    setFilterObj({
                                        ...filterObj,
                                        search:e?.target?.value
                                    })
                                }}
                            />
                        </div>
                    </div>
                    <div class="d-md-none pointer">
                        <i class="bi bi-funnel-fill px-3 py-2 seller-box"></i>
                    </div>
                </div>

                <div className='peta-height-fix'>
                    <div className='custom-order-list-table-overflow'>
                        <div className='custom-order-list-table-overflow-div'>
                            <table className='custom-order-list-table'>
                                <thead className=''>
                                    <tr className='fs-12 fs-sm-10 fw-medium p-color'>
                                    {
                                        ['rto-delivered']?.includes(orderStatus) ? (
                                            <>
                                                <th style={{width:'20px!important'}}>
                                                    <label className='fs-12 d-flex align-items-center gap-1 pointer'>
                                                        <Input
                                                            type='checkbox'
                                                            checked={checkAll}
                                                            onChange={(e) => manageCheckBox(e?.target?.checked)}
                                                            className='mt-0'
                                                            style={{width:'18px', height:'18px'}}
                                                        />
                                                        <span>All</span>
                                                    </label>
                                                </th>
                                                <th>Order ID</th>
                                                {/* <th>Date</th> */}
                                                <th>Delivered Date</th>
                                                <th>Image</th>
                                                <th>Product Name</th>
                                                <th>Quantity</th>
                                                <th>Left Quantity</th>
                                                <th>Price</th>
                                                <th>Remaning Days</th>
                                                <th>Action</th>
                                            </>
                                        ) : (
                                            <>
                                                <th>Order ID</th>
                                                <th>Date</th>
                                                <th>Customer Detail</th>
                                                <th>Net Amount</th>
                                                {/* <th>Remaining Time</th> */}
                                                <th>Status</th>
                                                <th>Action</th>
                                            </>
                                        )
                                    }
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    rtoOrderData?.map((row, index) => {
                                        const daysLeft = moment(['rto-delivered']?.includes(orderStatus) ? row?.orderDeliveredDate : row?.orderDate)?.add(60, 'days').diff(moment(), 'days')
                                        const colorData = row?.variantData?.filter(e => ['color', 'colors', 'colour', 'colours']?.includes(e?.variantParentName))
                                        return (
                                            <tr key={index} class="custom-order-list-bar-item fs-12 fs-sm-10">
                                            {
                                                ['rto-delivered']?.includes(orderStatus) ? (
                                                    <>
                                                        <td>
                                                        {
                                                            row?.productQuantity > row?.usedQuantity ? (
                                                                <Input
                                                                    type='checkbox'
                                                                    checked={row?.checked}
                                                                    onChange={(e) => {
                                                                        rtoOrderData[index].checked = e?.target?.checked
                                                                        setRtoOrderData([...rtoOrderData])
                                                                    }}
                                                                    className='mt-0'
                                                                    style={{width:'18px', height:'18px'}}
                                                                />
                                                            ) : (<>-</>)
                                                        }
                                                        </td>
                                                        <td style={{width:'fit-content'}} >{row?.orderChildNumber}</td>
                                                        <td style={{width:'fit-content'}} >{row?.orderDate}</td>
                                                        {/* <td style={{width:'fit-content'}} >{row?.orderDate}</td> */}
                                                        <td style={{width:'fit-content'}} >
                                                            <div className='list-img border' style={{width:'90px!important', height:'90px!important'}} >
                                                                <ProductPreviewImage
                                                                    colorCode={colorData?.length > 0 ? colorData[0]?.variantCode : '#ffffff'}
                                                                    objectData={{ preview:row?.productThumbnail?? null }}
                                                                    isButtonShow={false}
                                                                />
                                                            </div>
                                                        </td>
                                                        <td style={{width:'fit-content'}} >
                                                            <div className='d-flex flex-column align-items-start'>
                                                                <span className='mb-1'>{row?.productName}</span>
                                                                <div className='d-flex gap-2 align-items-center text-capitalize text-dark fw-semibold'>
                                                                {
                                                                    row?.variantData?.map(e => <>
                                                                        <span className='border rounded-3 px-2 alert-warning'>{e?.variantParentName} : {e?.variantName}</span>
                                                                    </>)
                                                                }
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td style={{width:'fit-content'}} >{row?.productQuantity}</td>
                                                        <td style={{width:'fit-content'}} >{
                                                            `${row?.productQuantity > row?.usedQuantity ? row?.productQuantity - row?.usedQuantity : 0} Qty`
                                                        }</td>
                                                        <td style={{width:'fit-content'}} >{row?.productPrice}</td>
                                                        <td>
                                                            <div
                                                                role="alert"
                                                                class={`fade m-0 text-center fw-semibold py-1 px-2 px-sm-3 fs-12 fs-sm-10 alert alert-${(daysLeft <= 0) ? 'danger' :'warning'} show`}
                                                                style={{width:'fit-content'}}
                                                            ><span>{daysLeft > 0 ? daysLeft + ' days left' : 'Expired'}</span></div>
                                                        </td>
                                                        <td>
                                                            <Button
                                                                className='bg-success p-2 border-0'
                                                                onClick={() => deleteRow(row?.id)}
                                                            >
                                                                <FaTrash />
                                                            </Button>
                                                        </td>
                                                    </>
                                                ) : (
                                                    <>
                                                        <td style={{width:'fit-content'}} >{row?.orderAnotherNumber}</td>
                                                        <td style={{width:'fit-content'}} >{row?.orderDate}</td>
                                                        <td>
                                                            <div class="d-flex flex-column">
                                                                <span className='text-capitalize'>{row?.userData?.name}</span>
                                                                <span>{row?.userData?.email}</span>
                                                            </div>
                                                        </td>
                                                        <td>{keyCode?.inr}{row?.orderNetAmount}</td>
                                                        {/* <td>
                                                            <div
                                                                role="alert"
                                                                class={`fade m-0 text-center fw-semibold py-1 px-2 px-sm-3 fs-12 fs-sm-10 alert alert-${(row?.orderStatus == 'rto-delivered' && daysLeft <= 0) ? 'danger' :'warning'} show`}
                                                                style={{width:'fit-content'}}
                                                            >
                                                            {
                                                                row?.orderStatus == 'rto-delivered' ? (
                                                                    <span>{daysLeft > 0 ? daysLeft + ' days left' : 'Expired'}</span>
                                                                ) : (<span>count after delivered</span>)
                                                            }
                                                            </div>
                                                        </td> */}
                                                        <td>
                                                            <div
                                                                className={`rounded m-0 text-center fw-semibold text-capitalize py-1 px-2 px-sm-3 fs-12 fs-sm-10 text-black ${rtoOrderStatusData?.filter(e => e?.value == row?.orderStatus)[0]?.class}`}
                                                                style={{width:'fit-content'}}
                                                            >{row?.orderStatus?.replace(/-/g, ' ')}</div>
                                                        </td>
                                                        <td>
                                                            <OrderActionButton row={row} />
                                                        </td>
                                                    </>
                                                )
                                            }
                                            </tr>
                                        )
                                    })
                                }
                                {
                                    rtoOrderData?.length <= 0 && (
                                        <tr>
                                            <td colSpan={['rto-delivered']?.includes(orderStatus) ? 10 : 7} align='center'>
                                                <div className='custom-transaction-tab d-grid justify-content-center text-center gap-2'>
                                                    <div>
                                                        <GiNotebook />
                                                    </div>
                                                    <h5 className='fw-semibold'>{rtoOrderDataStatus == 0 ? 'No returns yet' : 'Please wait a moment'}</h5>
                                                </div>                                                                    
                                            </td>
                                        </tr>
                                    )
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RtoOrderList