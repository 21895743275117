import axios from "axios"
import { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { frontEnd_API, header, token } from "../Config/Config"
import { useDispatch, useSelector } from "react-redux"
import { updateUser } from '../Store/Slices/UserSlice';
import { Link, useLocation } from "react-router-dom"
import Swal from "sweetalert2"

const SellerSubscriptionPlanDetail = () => {

    const dispatch = useDispatch()
    const location = useLocation()

    const storeDetail = useSelector((state) => state?.store?.value)
    const sellerDetail = useSelector((state) => state?.user?.value)
    const [planDetail, setPlanDetail] = useState({})

    const getPlanDetail = async () => {
        const data = await axios?.get(frontEnd_API?.currentPlanDetail, header)
        setPlanDetail({ ...data?.data?.data })
        await dispatch(updateUser({ ...data?.data?.data }))
    }

    useEffect(() => {
        if (sellerDetail?.sellerSubscriptionId > 0 && token) getPlanDetail()
    }, [])

    // function for send email for verification -----
    const sendVerificationEmal = async () => {
        console.log(frontEnd_API?.sendVerifictionMail)
        try {
            const { data, status } = await axios.get(`${frontEnd_API?.sendVerifictionMail}`, header)
            Swal.fire(
                ``,
                `${data?.message}`,
                'success'
            );
            console.log('data 31 :: ', data)
        } catch (error) {
            console.log('error :: ', error)
            Swal.fire(
                ``,
                `${error?.response?.data?.message ?? "Error Occurs"}`,
                'error'
            );
        }
    }
    // -----

    return (
        <>
            {
                (sellerDetail?.isEmailVerified <= 0 && storeDetail?.id > 0 && !location.pathname.startsWith("/verifyemail")) && (
                    <div className="py-4" style={{ backgroundColor: "rgb(247, 247, 247)" }}>
                        <div className={(location.pathname.startsWith("/orders") || location.pathname.startsWith("/returns")) ? "container-fluid px-2 px-sm-3 px-md-5" : "container"}>
                            <Row>
                                <Col>
                                    <div className="bg-warning text-dark py-2 py-sm-3 px-3 fs-14 fs-lg-12 fs-sm-10 fw-semibold first-capitalize rounded d-flex align-items-center justify-content-between">
                                        <div style={{ maxWidth: "calc(100%)" }}>
                                            Please verify your email to activate your account – check your inbox or&nbsp;
                                            <Link
                                                className="text-dark"
                                                style={{ minWidth: "100px" }}
                                                onClick={() => sendVerificationEmal()}
                                            >
                                                click here
                                            </Link>
                                            &nbsp;to resend the verification email.
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                )
            }
            {
                (planDetail?.sellerSubscriptionId > 0 && planDetail?.leftDays <= 3) &&
                (
                    <div className="py-4" style={{ backgroundColor: "rgb(247, 247, 247)" }}>
                        <div className={(location.pathname.startsWith("/orders") || location.pathname.startsWith("/returns")) ? "container-fluid px-2 px-sm-3 px-md-5" : "container"}>
                            <Row>
                                <Col>
                                    <div className="bg-danger text-white py-2 py-sm-3 px-3 fs-14 fs-lg-12 fs-sm-10 fw-semibold first-capitalize rounded d-flex align-items-center justify-content-between">
                                        <div style={{ maxWidth: "calc(100% - 100px)" }}>
                                            {
                                                planDetail?.leftDays > 1 ? (<>Your plan is about to expire. Only {planDetail?.leftDays} {planDetail?.leftDays > 1 ? 'days' : 'day'} left to renew!</>) : (<>Your plan has expired. Please renew to continue enjoying our services.</>)
                                            }
                                        </div>
                                        <div>
                                            <Link
                                                className="text-white text-decoration-none border border-white px-2 py-1 rounded-2"
                                                to={'/subscription'}
                                                style={{ minWidth: "100px" }}
                                            >Upgrade now</Link>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default SellerSubscriptionPlanDetail