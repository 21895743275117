import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, Row, Table } from 'react-bootstrap';
import { Label } from 'react-konva';
import axios from 'axios';
import { frontEnd_API, header } from '../../../Config/Config';
import { toast, ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';
import { keyCode } from '../../../Config/Constant';

function ProductProviderSwitch() {

    const location = useLocation();
    const navigate = useNavigate();
    
    const product = location?.state
    console.log("location?.state :: ", location?.state)
    const locationData = location?.state?.data

    const [priceCountBoxStatus, setPriceCountBoxStatus] = useState(false)
    const [selectedVariantData, setSelectedVariantData] = useState([])
    
    // state section ------------
    const [productFormSubmitStatus, setProductFormSubmitStatus] = useState(false)
    const [productForm, setProductForm] = useState({})
    // ------------

    // const roundToTwoDecimalPlaces = (num = 0) => Math.round(num * 100) / 100
    const roundToTwoDecimalPlaces = (num = 0) => Math.round(num)


    // function for generate/create product cartesian ------------
    const generateVariantCartesian = (arr = []) => {
        if (arr?.length > 0) {
            const cartesian = arr.reduce((acc, array) => {
                return acc.flatMap(a => array.map(b => [...a, b]));
            }, [[]])?.map(e => ({ variant: e, stock: 50, isAvailable: true }));
            return cartesian
        } else {
            return []
        }
    }
    // ------------

    // function for manage product form data ------------
    const manageProductFormData = async () => {
        const productData = { ...product.product, ...product.provider }
        console.log("productData59  :: ", productData)

        productData.name = productData?.name
        productData.platformChargeAmount = 0
        productData.designData = productData?.designSidesData
        productData.providerPrice = Number(productData?.productPrice)
        productData.providerDesignPrice = productData?.designData?.reduce((sum, x) => sum + x?.designCharge, 0)
        productData.providerPayble = productData?.productPrice + productData?.providerDesignPrice
        productData.payblePrice = roundToTwoDecimalPlaces(productData.platformChargeAmount + productData?.providerPayble)
        
        productData.profitInAmount = Number(productData?.profitInAmount?? 200)
        productData.profitInPercentage = roundToTwoDecimalPlaces(Number(Number(productData?.profitInAmount) * 100 / Number(productData?.payblePrice)))

        productData.price = productData.payblePrice + productData.profitInAmount
        productData.mrp = roundToTwoDecimalPlaces(productData?.price)
        productData.variantData = productData?.availableVariantData?? []

        let cartesianData = []
        
        if (productData?.variantData)
            cartesianData = generateVariantCartesian(productData?.variantData?.map(e => e?.data))

        productData.cartesianData = cartesianData?.map(e => {
            const variantPrice = e?.variant?.reduce((sum, a) => sum + a?.providerPrice, 0)
            const providerPrice = Number(roundToTwoDecimalPlaces(variantPrice) + productData.providerPrice + productData.providerDesignPrice)
            const payblePrice = roundToTwoDecimalPlaces(providerPrice);
            const profitAmount = variantPrice > 0 ? roundToTwoDecimalPlaces(payblePrice * productData.profitInPercentage / 100) : productData?.profitInAmount
            const price = payblePrice + roundToTwoDecimalPlaces(profitAmount)
            const mrp = price

            return {
                ...e,
                providerPrice,
                payblePrice,
                profitPercentage: productData?.profitInPercentage,
                profitAmount,
                price,
                mrp,
                isPrimary: variantPrice <= 0
            }
        })
        if (productData?.variantData)
            setSelectedVariantData([...productData?.variantData?.map(e => e?.name)])

        setProductForm(
            {
                productId:productData?.productId,
                platformProviderCommissionPercentage: productData?.platformCommissionPercentage,
                providerId: productData?.providerId,
                providerBusinessId: productData?.providerBusinessId,
                providerProductId: productData?.providerProductId ?? 0,
                productName: productData?.name,
                providerPrice: productData?.productPrice,
                providerDesignPrice: productData?.providerDesignPrice,
                providerPayble: productData?.providerPayble,
                platformChargePercentage: productData.platformChargePercentage,
                platformChargeAmount: productData.platformChargeAmount,
                payblePrice: productData.payblePrice,
                profitInPercentage: productData.profitInPercentage,
                previousProfitInPercentage: productData.profitInPercentage,
                profitInAmount: productData.profitInAmount,
                previousProfitInAmount: productData.profitInAmount,
                price: productData.price,
                mrp: productData.mrp,
                previousMrp: productData.price,
                designData: productData?.designData ?? [],
                cartesianData: productData.cartesianData ?? [],
                variantData: productData?.variantData ?? [],
            }
        )
    }
    // ------------

    // function for manage product price ------------
    const manageProductPrice = async (obj = { from: 'amt', val: 0 }) => {
        if (['amt', 'per'].includes(obj?.from)) {
            productForm.profitInPercentage = productForm.profitInAmount = 0
            productForm.price = productForm.payblePrice
            if (obj.from === 'amt') {
                productForm.profitInPercentage = roundToTwoDecimalPlaces((obj.val * 100 / productForm.payblePrice))
                productForm.profitInAmount = obj.val
                productForm.previousProfitInPercentage = productForm.profitInPercentage
            }
            if (obj.from === 'per') {
                productForm.profitInPercentage = obj.val
                productForm.profitInAmount = roundToTwoDecimalPlaces((productForm?.payblePrice) * obj.val / 100)
                productForm.previousProfitInAmount = productForm.profitInAmount
            }
            productForm.price = productForm.payblePrice + productForm.profitInAmount
        }
        if (obj.from == 'mrp')
            productForm.mrp = obj.val

        setProductForm({ ...productForm })
    }
    // ------------

    // function for save product detail ------------
    const productFormSubmit = async () => {

        try {

            delete productForm?.previousProfitInPercentage
            delete productForm?.previousProfitInAmount
            delete productForm?.previousMrp
            delete productForm?.desginPlatformCharge

            productForm.platformChargeAmount = Number(productForm?.platformChargeAmount ?? 0)
            console.log('productForm  :: ', productForm)
            // return

            const response = await axios.put(`${frontEnd_API?.storeProduct}/switchprovider`, productForm, header);

            if (response?.status == 200) {
                setProductFormSubmitStatus(false)
                toast(response?.data?.message)
                navigate('/products')
            }
        } catch (error) {
            setProductFormSubmitStatus(false)
            console.log("err ::: ", error)
            toast.warning(error?.response?.data?.message?? 'Something is wrong')
        }
    }
    // ------------

    // function for update all variant price ------------
    const updateVariantPrice = (obj = {}) => {

        const data = productForm?.cartesianData?.map((row) => {

            if (['per', 'amt'].includes(obj.from)) {
                if (obj.from == 'per') {
                    row.profitPercentage = productForm.profitInPercentage
                    row.profitAmount = roundToTwoDecimalPlaces(Number(row?.payblePrice) * row.profitPercentage / 100)
                }

                if (obj.from == 'amt') {
                    row.profitAmount = productForm.profitInAmount
                    row.profitPercentage = roundToTwoDecimalPlaces(Number(row.profitAmount * 100 / row.payblePrice))
                }

                row.price = row.payblePrice + row.profitAmount
                row.mrp = row?.price > row?.mrp ? row?.price : row?.mrp
            }
            if (obj.from == 'mrp')
                row.mrp = roundToTwoDecimalPlaces(productForm.mrp)

            return row
        })

        productForm.cartesianData = data
        setProductForm({ ...productForm })
    }
    // ------------

    // function for manage variant price ------------
    const manageVariantPrice = (obj = { from: 'amt' }) => {

        if (obj.from) {
            const row = productForm?.cartesianData[obj?.index]

            if (['amt', 'per'].includes(obj.from)) {
                if (obj.from == 'per') {
                    row.profitPercentage = obj.value
                    row.profitAmount = roundToTwoDecimalPlaces(Number(row?.payblePrice) * row.profitPercentage / 100)
                }

                if (obj.from == 'amt') {
                    row.profitAmount = obj.value
                    row.profitPercentage = roundToTwoDecimalPlaces(Number(row.profitAmount * 100 / row.payblePrice))
                }
                row.price = row.payblePrice + row.profitAmount
                row.mrp = row?.price > row?.mrp ? row.price : row.mrp
            }
            if (obj.from == 'mrp')
                row.mrp = obj.value

            productForm.cartesianData[obj.index] = row

            setProductForm({ ...productForm })
        }
    }
    // ------------
    

    useEffect(() => {
        manageProductFormData()
    }, [product])

    return (
        <div className='custom-product-create position-relative'>
            <Container className='py-4'>
                <Row>
                    <Col xl='12'>
                        <Card className='rounded-0 mb-4'>
                            <CardHeader className='bg-transparent py-3'>
                                <h4 className='mb-0 fw-bolder'>Product Detail</h4>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col xl='12'>
                                        <div className='form-group mb-3'>
                                            <Label className='form-label'>Title</Label>
                                            <input
                                                className='form-control rounded-0 fs-6 py-2'
                                                placeholder='product title'
                                                value={productForm?.productName}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className='rounded-0 mb-4'>
                            <CardHeader className='bg-transparent py-3'>
                                <h4 className='mb-0 fw-bolder'>Price & Variant</h4>
                            </CardHeader>
                            <CardBody>
                                <Row xl={5} md={3} xs={1}>
                                    <Col>
                                        <div className='form-group mb-3'>
                                            <Label className='form-label d-flex justify-content-between align-items-center'>
                                                <span>Payable amount</span>
                                                <div className='position-relative'>
                                                    <span onClick={() => setPriceCountBoxStatus(!priceCountBoxStatus)} className="bi bi-info-circle-fill mr-1"></span>
                                                    {
                                                        (priceCountBoxStatus) && (
                                                            <div className='position-absolute border rounded-2 p-2 bg-white bottom-100 end-50 fs-12' style={{ width: '250px!important;' }}>
                                                                <div className='d-flex gap-3 justify-content-between ml-2'>
                                                                    <span className='text-nowrap'>Provider price</span>
                                                                    <span className='fw-bold'>{productForm?.providerPrice}</span>
                                                                </div>
                                                                <div className='d-flex gap-3 justify-content-between ml-2'>
                                                                    <span className='text-nowrap'>Design charges</span>
                                                                    <span className='fw-bold'>{productForm?.providerDesignPrice}</span>
                                                                </div>
                                                                {/* {
                                                                    productForm?.platformChargeAmount > 0 && (
                                                                        <div className='d-flex gap-3 justify-content-between ml-2'>
                                                                            <span className='text-nowrap'>Platform fee</span>
                                                                            <span className='fw-bold'>{productForm?.platformChargeAmount}</span>
                                                                        </div>
                                                                    )
                                                                }
                                                                {
                                                                    productForm?.taxPercentage > 0 && (
                                                                        <div className='d-flex gap-3 justify-content-between ml-2 d-none'>
                                                                            <span className='text-nowrap'>Tax ({productForm?.taxPercentage}%)</span>
                                                                            <span className='fw-bold'>{productForm?.taxAmount}</span>
                                                                        </div>
                                                                    )
                                                                } */}
                                                                <div className='d-flex gap-3 justify-content-between ml-2'>
                                                                    <span className='text-nowrap'>Shipping as per actual</span>
                                                                    {/* <span className='fw-bold'>{productForm?.shippingCharge}</span> */}
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </Label>
                                            <input
                                                className='form-control rounded-0 fs-6 py-2'
                                                value={productForm?.payblePrice}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className='form-group mb-3'>
                                            <Label className='form-label'>Profit (%)</Label>
                                            <input
                                                className='form-control rounded-0 fs-6 py-2'
                                                value={productForm?.profitInPercentage}
                                                onChange={(e) => manageProductPrice({ from: 'per', val: parseFloat(e?.target?.value > 0 ? e?.target?.value : 0) })}
                                                onBlur={(e) => {
                                                    if (productForm.profitInPercentage != productForm.previousProfitInPercentage) {
                                                        Swal.fire({
                                                            title: `Do you want to apply this change to all the variant??`,
                                                            icon: 'warning',
                                                            showCancelButton: true,
                                                            confirmButtonColor: '#04ac84',
                                                            cancelButtonColor: '#d33',
                                                            confirmButtonText: 'Confirm',
                                                            cancelButtonText: 'No',
                                                        }).then(async (result) => {
                                                            if (result.isConfirmed) {
                                                                productForm.previousProfitInPercentage = productForm.profitInPercentage
                                                                productForm.mrp = productForm?.price > productForm?.mrp ? productForm?.price : productForm?.mrp
                                                                productForm.previousMrp = productForm?.mrp
                                                                setProductForm({ ...productForm })
                                                                updateVariantPrice({ from: 'per' })
                                                                // updateVariantPrice({ from: 'mrp' })
                                                            } else {
                                                                manageProductPrice({ from: 'per', val: Number(productForm?.previousProfitInPercentage ?? 0) })
                                                            }
                                                        });
                                                    }
                                                }}
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className='form-group mb-3'>
                                            <Label className='form-label'>Profit ({keyCode?.inr})</Label>
                                            <input
                                                className='form-control rounded-0 fs-6 py-2'
                                                value={productForm?.profitInAmount}
                                                onChange={(e) => manageProductPrice({ from: 'amt', val: parseFloat(e?.target?.value > 0 ? e?.target?.value : 0) })}
                                                onBlur={(e) => {
                                                    if (productForm.profitInAmount != productForm.previousProfitInAmount) {
                                                        Swal.fire({
                                                            title: `Do you want to apply this change to all variant??`,
                                                            icon: 'warning',
                                                            showCancelButton: true,
                                                            confirmButtonColor: '#04ac84',
                                                            cancelButtonColor: '#d33',
                                                            confirmButtonText: 'Confirm',
                                                            cancelButtonText: 'No',
                                                        }).then(async (result) => {
                                                            if (result.isConfirmed) {
                                                                productForm.previousProfitInAmount = productForm.profitInAmount
                                                                productForm.mrp = productForm?.price > productForm?.mrp ? productForm?.price : productForm?.mrp
                                                                productForm.previousMrp = productForm?.mrp
                                                                setProductForm({ ...productForm })
                                                                updateVariantPrice({ from: 'amt' })
                                                                // updateVariantPrice({ from: 'mrp' })
                                                            } else {
                                                                manageProductPrice({ from: 'amt', val: Number(productForm?.previousProfitInAmount ?? 0) })
                                                            }
                                                        });
                                                    }
                                                }}
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className='form-group mb-3'>
                                            <Label className='form-label d-flex align-items-end'>
                                                Selling Price
                                                {/* Selling Price{productForm?.taxPercentage > 0 && (<div className='text-dark fs-12 fw-semibold'>&nbsp;({productForm?.taxPercentage}% incl.)</div>)} */}
                                            </Label>
                                            <input
                                                className='form-control rounded-0 fs-6 py-2'
                                                value={productForm?.price}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className='form-group mb-3'>
                                            <Label className='form-label'>Set MRP</Label>
                                            <input
                                                className='form-control rounded-0 fs-6 py-2'
                                                value={productForm?.mrp}
                                                onChange={(e) => manageProductPrice({ from: 'mrp', val: parseFloat(e?.target?.value > 0 ? e?.target?.value : 0) })}
                                                onBlur={(e) => {
                                                    console.log('productForm.mrp != productForm.previousMrp :: ', productForm.mrp != productForm.previousMrp)
                                                    if (productForm.mrp != productForm.previousMrp) {

                                                        Swal.fire({
                                                            title: `Do you want to apply this MRP to all variant??`,
                                                            icon: 'warning',
                                                            showCancelButton: true,
                                                            confirmButtonColor: '#04ac84',
                                                            cancelButtonColor: '#d33',
                                                            confirmButtonText: 'Confirm',
                                                            cancelButtonText: 'No',
                                                        }).then(async (result) => {
                                                            if (result.isConfirmed) {
                                                                productForm.previousMrp = productForm.mrp
                                                                setProductForm({ ...productForm })
                                                                updateVariantPrice({ from: 'mrp' })
                                                            } else {
                                                                manageProductPrice({ from: 'mrp', val: Number(productForm?.previousMrp ?? 0) })
                                                            }
                                                        });
                                                    }
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    {
                                        productForm?.cartesianData && (
                                            <Col xl='12' className='cartesian'>
                                                <hr />
                                                <div className='table-responsive mb-4'>
                                                    <Table className='table table-centered table-hover align-middle table-nowrap mb-0'>
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={6}>
                                                                    <h5 className='mb-0'>Product Variant Cartesian</h5>
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                {selectedVariantData?.map((row) => <th className='fs-sm-14 text-capitalize'>{row}</th>)}
                                                                <th className='fs-sm-14'>Production cost</th>
                                                                <th className='fs-sm-14'>Profit (%)</th>
                                                                <th className='fs-sm-14'>Profit ({keyCode?.inr})</th>
                                                                <th className='fs-sm-14'>Sale price</th>
                                                                <th className='fs-sm-14'>MRP</th>
                                                                {/* <th>Stock</th> */}
                                                                <th className='fs-sm-14'>Available</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                productForm?.cartesianData?.map((row, index) => <>
                                                                    <tr className={row?.isPrimary ? 'disabled' : ''}>
                                                                        {row?.variant?.map((val) => <td className='fs-sm-14'>{val?.label}</td>)}
                                                                        <th>
                                                                            <input
                                                                                className='form-control rounded-0 fs-sm-14'
                                                                                value={row?.payblePrice}
                                                                                style={{ width: '100px' }}
                                                                                disabled
                                                                            />
                                                                        </th>
                                                                        {
                                                                            row?.isPrimary ? (
                                                                                <>
                                                                                    <th>
                                                                                        <input
                                                                                            className='form-control rounded-0 fs-sm-14'
                                                                                            value={row?.profitPercentage}
                                                                                            style={{ width: '100px' }}
                                                                                            disabled
                                                                                        />
                                                                                    </th>
                                                                                    <th>
                                                                                        <input
                                                                                            className='form-control rounded-0 fs-sm-14'
                                                                                            value={row?.profitAmount}
                                                                                            style={{ width: '100px' }}
                                                                                            disabled
                                                                                        />
                                                                                    </th>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <th>
                                                                                        <input
                                                                                            type='number'
                                                                                            className='form-control rounded-0 fs-sm-14'
                                                                                            value={row?.profitPercentage}
                                                                                            style={{ width: '100px' }}
                                                                                            onChange={(e) => manageVariantPrice({ index, value: e?.target?.value > 0 ? Number(e?.target?.value) : 0, from: 'per' })}
                                                                                        />
                                                                                    </th>
                                                                                    <th>
                                                                                        <input
                                                                                            type='number'
                                                                                            className='form-control rounded-0 fs-sm-14'
                                                                                            min='0'
                                                                                            value={row?.profitAmount}
                                                                                            style={{ width: '100px' }}
                                                                                            onChange={(e) => manageVariantPrice({ index, value: e?.target?.value > 0 ? Number(e?.target?.value) : 0, from: 'amt' })}
                                                                                        />
                                                                                    </th>
                                                                                </>
                                                                            )
                                                                        }
                                                                        <th>
                                                                            <input
                                                                                className='form-control rounded-0 fs-sm-14'
                                                                                value={row?.price}
                                                                                style={{ width: '100px' }}
                                                                                disabled
                                                                            />
                                                                        </th>
                                                                        <th>
                                                                            <input
                                                                                className='form-control rounded-0 fs-sm-14'
                                                                                value={row?.mrp}
                                                                                style={{ width: '100px' }}
                                                                                onChange={(e) => manageVariantPrice({ index, value: e?.target?.value > 0 ? Number(e?.target?.value) : 0, from: 'mrp' })}
                                                                            />
                                                                        </th>
                                                                        <td>
                                                                            <label className="switch">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    checked={row?.isAvailable}
                                                                                    value={row?.isAvailable}
                                                                                    onChange={(e) => {
                                                                                        productForm.cartesianData[index].isAvailable = !row?.isAvailable
                                                                                        setProductForm({ ...productForm })
                                                                                    }}
                                                                                />
                                                                                <span className="slider"></span>
                                                                            </label>
                                                                        </td>
                                                                    </tr>
                                                                </>)
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </Col>
                                        )
                                    }
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className='rounded-0 mb-4'>
                            <CardBody>
                                <div className='d-flex gap-2 align-items-center justify-conten-end'>
                                {
                                    productFormSubmitStatus ? (
                                        <Button
                                            className='text-capitalize btn btn-success rounded-0 py-2 px-3 fs-6'
                                            color='success'
                                            disabled
                                            type='button'
                                            style={{ minWidth: "139px" }}
                                        >
                                            Please wait..    
                                        </Button>
                                    ) : (
                                        <Button
                                            className='text-capitalize btn btn-success rounded-0 py-2 px-3 fs-6'
                                            color='success'
                                            onClick={() => {
                                                setProductFormSubmitStatus(true)
                                                setTimeout(() => {
                                                    productFormSubmit()
                                                }, 1200)
                                            }}
                                            type='button'
                                            disabled={productFormSubmitStatus}
                                            style={{ minWidth: "139px" }}
                                        >
                                            Update product
                                        </Button>
                                    )
                                }
                            </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <ToastContainer />
        </div>
    )
}

export default ProductProviderSwitch