import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Modal, Row, Spinner } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { pricingList, subscriptionProduct } from '../../Data/localData';
import { frontEnd_API, header, siteConfig } from '../../Config/Config';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from '../../Store/Slices/UserSlice';
import Swal from 'sweetalert2';
import { FaDotCircle } from "react-icons/fa";
import { RiCalendarCloseLine } from "react-icons/ri";
import moment from 'moment';

function SellerSubscription() {

    const [subList, setSubList] = useState();
    const [show, setShow] = useState(false);
    const [loader, setLoader] = useState(false);
    const [selectedData, setSelectedData] = useState();
    const [subValue, setSubValue] = useState();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userData = useSelector((state) => state.user.value);
    console.log("userData?.expireDate 24 :: ", userData?.expireDate)
    const getSubscriptionList = async () => {
        try {
            const { data } = await axios.get(frontEnd_API.pricing, header)
            setSubList(data?.data);
            console.log("data::", data);
        }
        catch (e) {
            console.log("err::", e);
        }
    }

    useEffect(() => {
        getSubscriptionList();
    }, [])

    const handleSubscription = async () => {
        try {
            const response = await axios.post(frontEnd_API.sellersubscription, subValue, header)
            console.log("data::", response?.data);
            dispatch(updateUser({
                storeLimit: response?.data?.data?.storeLimit,
                ...response?.data?.data
            }));
            if (userData?.sellerSubscriptionId == 0) {
                Swal.fire(
                    `"${response?.data?.data?.subscription?.name}" plan Activated !`,
                    `Congratulation! Your subscripiton plan activated successfully!`,
                    'success'
                );
            }
            else {
                Swal.fire(
                    `"${response?.data?.data?.subscription?.name}" plan updated !`,
                    `Congratulation! Your subscripiton plan updated successfully!`,
                    'success'
                );
            }
            setShow(false);
        }
        catch (e) {
            Swal.fire(
                `Request failed`,
                `${e?.response?.data?.message}`,
                'error'
            );
            Swal.fire({
                title: `Request failed`,
                text: `${e?.response?.data?.message}`,
                icon: 'error',
                showCancelButton: true,
                confirmButtonColor: '#04ac84',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Recharge now',
                cancelButtonText: 'close',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    navigate(`/payment/-subscription`);
                    setShow(false)
                }
                else {
                    setShow(false)
                }
            });
            console.log("Err::", e);
        }
    }

    function daysUntilExpire(expireDate) {
        const today = moment();
        const expiration = moment(expireDate, "YYYY-MM-DD HH:mm:ss");

        if (expiration.isBefore(today))
            return "The date has already expired."

        const daysLeft = expiration.diff(today, 'days')
        return `${daysLeft} days left to expire`;
    }

    // const defaultDate = "2024-12-29 05:32:50";

    document.title = `Subscription | ${siteConfig?.name}`;
    return (
        <div>
            <Container fluid className='p-0 d-grid seller-subscription-main-container overflow-hidden'>

                {
                    // (userData?.sellerSubscriptionId != 0) &&
                    <Container className='d-grid custom-active-subscription-plan py-5 px-4 px-sm-2'>
                        <div className="card-container d-grid p-3 p-sm-4 gap-3 gap-sm-4">
                            <span className='d-grid d-sm-flex justify-content-sm-between align-items-sm-center gap-2'>
                                <Button variant='success' className='flex-center-align gap-2 fw-semibold bg-temp cursor-default fs-14 fs-sm-12'>
                                    <FaDotCircle /> Active Plan
                                </Button>
                                {!userData?.expired ?
                                    <>
                                        <h1 className='color-temp fw-semibold h2 text-center'>{userData?.subscription?.name}</h1>
                                        <Button variant='success' className='flex-center-align gap-2 fw-semibold bg-temp cursor-default fs-12 fs-sm-10'>
                                            {/* <RiCalendarCloseLine className='fs-20' />{moment(userData?.subscription?.expireDate).format("hh:mm A - DD, MMM YYYY")} */}
                                            {/* <RiCalendarCloseLine className='fs-20' />{daysUntilExpire(userData?.subscription?.expireDate)} */}
                                            {/* <RiCalendarCloseLine className='fs-20' />{daysUntilExpire(userData?.expireDate)} */}
                                            <RiCalendarCloseLine className='fs-20' />
                                            {/* {userData?.leftDays > 0 ? `${userData?.leftDays} days left to expire` : 'The plan has expired. Kindly renew to enjoy the benefits.' } */}
                                            {userData?.leftDays > 0 ? `${userData?.leftDays} days left to expire` : 'Plan has already expired'}
                                        </Button>
                                    </> :
                                    <h1 className='color-temp fw-semibold h2 text-center' style={{ width: "calc(100% - 150px)" }}>{"Free Membership"}</h1>
                                }
                            </span>
                            {
                                userData?.subscription?.description?.length > 0 &&
                                <div className='flex-between-align justify-content-sm-around gap-2 flex-wrap gap-sm-3 py-2 py-sm-3 px-2 bi-border-rad border-color d-none' style={{ border: "1px solid" }}>
                                    {userData?.subscription?.description.map((item, index) => {
                                        return (
                                            <div key={index} className='d-flex align-items-center gap-2'>
                                                <span className='py-1 px-2 bg-petaTempBgColor color-temp' style={{ borderRadius: "50%" }}>
                                                    <i className="bi bi-check fs-20 fs-sm-18"></i>
                                                </span>
                                                <p className='m-0'>{item}</p>
                                            </div>
                                        )
                                    })}
                                </div>
                            }
                        </div>
                    </Container>
                }

                {/* From Home List */}
                <div className='custom-price-list'>
                    <Container fluid className='p-0 p-lg-3'>
                        <Container fluid className='custom-img-container p-0'>
                            <Container className='custom-img-section'>
                                <div className='custom-img-section-contain-color'></div>
                                <div className='custom-img-section-contain'></div>
                                <div className='custom-img-section-contain-blend-center'></div>
                                <div className='custom-img-section-contain-blend-left'></div>
                                <div className='custom-img-section-contain-blend-right'></div>
                                <div className='custom-slider-content pt-5 gap-4 gap-sm-4 d-grid'>
                                    {/* Content Part */}
                                    <div className='custom-slider-content-list flex-center-align align-items-start'>
                                        <div className='custom-slider-content-list-first lg-max-w-750px max-w-600px gap-4 d-grid'>
                                            <div className='text-center custom-slider-heading d-grid gap-4 px-4 px-sm-0'>
                                                <h1 className='h1 m-0 d-flex justify-content-center gap-2 gap-sm-3 flex-wrap pb-sm-2'>
                                                    Our
                                                    <span>Subscription</span>
                                                    List
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Image Part */}
                                    {/* <Container className='custom-slider-content-list-second cs-container-xl p-0 pb-5 text-center'>
                                        <Row className='justify-content-center gy-4 gy-xl-0'>
                                            {
                                                (subList?.length > 0) &&
                                                subList.map((item, index) => (
                                                    <Col className='col-12 col-sm-6 col-xl-4' key={index}>
                                                        <div className={`price-list-item p-3 ${item?.priceForMonthly == 0 ? "free-price-list-item" : ""}`}>
                                                            <div className='first-content d-grid p-3 p-sm-4 gap-3'>
                                                                <b className='fw-semibold fs-20 text-capitalize'>{item?.name}</b>
                                                                <h2 className='m-0 display-4 fw-semibold'>
                                                                    {(parseInt(item?.priceForMonthly) !== 0)
                                                                        ? `₹${item?.priceForMonthly}`
                                                                        : "Free"}
                                                                    <span className='h2 fw-semibold'>/ mo</span>
                                                                </h2>
                                                                <h5 className='m-0 fw-semibold'>
                                                                    {(parseInt(item?.priceForYearly) !== 0)
                                                                        ? `₹${item?.priceForYearly}`
                                                                        : "Free"}
                                                                    <span className='fw-semibold'>/ yr</span>
                                                                </h5>
                                                                <p className='m-0 px-sm-4'>
                                                                    Build <b className='fw-semibold'>{(parseInt(item?.storeLimit) !== 0) ? item?.storeLimit : "Unlimited"}</b>  {(parseInt(item?.priceForMonthly) !== 0) ? "stores with every pro feature." : 'website with regular feature.'}
                                                                </p>
                                                                <Link className='fw-semibold fs-18 fs-sm-16'
                                                                    // style={{ backgroundColor: '#04ac84', color: 'white' }}
                                                                    onClick={() => {
                                                                        setShow((parseInt(item?.priceForMonthly) !== 0) ? true : false)
                                                                        setSelectedData(item);
                                                                    }}
                                                                >
                                                                    {(parseInt(item?.priceForMonthly) !== 0) ? "Buy Now" : "Free"}
                                                                </Link>
                                                            </div>
                                                            <div className='second-content d-grid p-3 pt-sm-4 gap-3'>
                                                                {
                                                                    item?.description.map((item, index) => {
                                                                        return (
                                                                            <div key={index} className='d-flex align-items-center gap-2 text-capitalize'>
                                                                                <span>
                                                                                    <i className={`bi bi-${item?.value ? 'check' : 'x'} fs-25 fs-sm-14`}></i>
                                                                                </span>
                                                                                <p className='m-0'>{item?.label}</p>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                            {
                                                                (parseInt(item?.priceForMonthly) !== 0 && (item?.priceForMonthly * 12 - item?.priceForYearly) > 0) &&
                                                                <div className='custom-lable fs-14 fw-medium'>Save ₹{item?.priceForMonthly * 12 - item?.priceForYearly}</div>
                                                            }
                                                        </div>
                                                    </Col>
                                                ))
                                            }
                                            <span className='text-center my-5'>Select the plan that suits you best. <a href="https://printfuse.in/pricing" className='color-temp fs-14' target='_blank'>Click here</a> to compare all pricing plans.</span>
                                        </Row>
                                    </Container> */}
                                    <Container className='custom-slider-content-list-second cs-container-xl p-0 pb-5 text-center'>
                                        <Row className='justify-content-center gy-4 gy-xl-0'>
                                            {
                                                (pricingList?.length > 0) &&
                                                pricingList.map((item, index) => (
                                                    <Col className='col-12 col-sm-6 col-xl-4' key={index}>
                                                        <div className={`${(parseInt(item?.price) !== 0) ? "price-list-item" : "price-list-item-free"} p-3 ${item?.priceForMonthly == 0 ? "free-price-list-item" : ""}`}>
                                                            <div className='first-content d-grid p-3 p-sm-4 gap-3'>
                                                                <b className='fw-semibold fs-20 text-capitalize'>{item?.title}</b>
                                                                {
                                                                    (item?.price == "Custom") ?
                                                                        <h2 className='m-0 display-4 fw-semibold'>
                                                                            {item?.price}
                                                                        </h2> : <>
                                                                            <h2 className='m-0 display-4 fw-semibold'>
                                                                                {(parseInt(item?.price) !== 0)
                                                                                    ? `₹${item?.price}`
                                                                                    : "Free"}
                                                                                <span className='h2 fw-semibold'>/ mo</span>
                                                                            </h2>
                                                                            {(Number(item?.yearlyPrice) !== 0) &&
                                                                                <h5 className='m-0 fw-semibold'>
                                                                                    {(parseInt(item?.yearlyPrice) !== 0)
                                                                                        ? `₹${item?.yearlyPrice}`
                                                                                        : "Free"}
                                                                                    <span className='fw-semibold'>/ yr</span>
                                                                                </h5>}
                                                                        </>}

                                                                <p className='m-0 px-sm-4'>{item?.description}</p>

                                                                <Link
                                                                    className={`fw-medium fs-18 fs-sm-16 ${(Number(item?.price) == 0) ? 'bg-dark' : 'custom-bg-temp'}`}
                                                                    onClick={() => {
                                                                        if (Number(item?.price) != 0 && item?.price != "Custom") {

                                                                            if (userData?.sellerSubscriptionId > 0 && !userData?.expired) {
                                                                                Swal.fire(
                                                                                    `You're already on a premium plan`,
                                                                                    '',
                                                                                    'warning'
                                                                                );
                                                                                return
                                                                            }
                                                                            setShow((parseInt(item?.price) !== 0) ? true : false)
                                                                            setSelectedData(subList[index - 1]);
                                                                        }
                                                                    }}
                                                                    to={(Number(item?.price) == 0) ? '/create-store' : (item?.price == "Custom") ? 'https://printfuse.in/contact' : ''}
                                                                    target={(item?.price == "Custom") ? "_blank" : "_self"}
                                                                >
                                                                    {item?.linkText}
                                                                </Link>
                                                            </div>
                                                            <div className='second-content d-grid p-3 pt-sm-4 gap-3'>
                                                                {
                                                                    item?.features.map((item, index) => {
                                                                        return (
                                                                            <div key={index} className='d-flex align-items-center text-start p-color fs-14 gap-2 text-capitalize'>
                                                                                <span>
                                                                                    <i className={`bi bi-check fs-18 fs-sm-14`}></i>
                                                                                </span>
                                                                                <p className='m-0'>{item}</p>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                            {
                                                                (item?.isPopular) &&
                                                                <div className='custom-lable fs-12 fw-semibold text-uppercase'>Popular</div>
                                                            }
                                                        </div>
                                                    </Col>
                                                ))
                                            }
                                            <span className='text-center my-5'>Select the plan that suits you best. <a href="https://printfuse.in/pricing" className='color-temp fs-14' target='_blank'>Click here</a> to compare all pricing plans.</span>
                                        </Row>
                                    </Container>
                                </div>
                            </Container>
                        </Container>
                    </Container>
                </div>

                <Modal show={show} onHide={() => setShow(false)} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton className='text-center'>
                        <Modal.Title id="contained-modal-title-vcenter text-center w-100 d-grid"><h5 className='m-0 fw-semibold text-capitalize'>{selectedData?.title}</h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="custom-radio-group">
                            <h6 className='mb-1 text-center'>Select plan duration</h6>
                            <label className={`custom-radio-container align-items-end ${(subValue?.type == "monthly") ? "custom-bg-temp text-light" : ""}`}>
                                <input
                                    type="radio"
                                    name="custom-radio"
                                    defaultValue="option1"
                                    className='d-none'
                                    onChange={() => setSubValue({
                                        subscriptionId: selectedData?.id,
                                        type: "monthly",
                                        amount: parseInt(selectedData?.priceForMonthly)
                                    })}
                                />
                                <span className="custom-radio-checkmark d-none" />
                                ₹{selectedData?.priceForMonthly} / mo <span className="fs-14 ps-2">(Renew Monthly)</span>
                            </label>
                            <label className={`custom-radio-container ${(subValue?.type == "yearly") ? "custom-bg-temp text-light" : ""}`}>
                                <input
                                    type="radio"
                                    name="custom-radio"
                                    defaultValue="option2"
                                    className='d-none'
                                    onChange={() => setSubValue({
                                        subscriptionId: selectedData?.id,
                                        type: "yearly",
                                        amount: parseInt(selectedData?.priceForYearly)
                                    })}
                                />
                                <span className="custom-radio-checkmark d-none" />
                                <span>
                                    ₹{selectedData?.priceForYearly} / yr <span className="fs-14 ps-2">(Renew Yearly)</span>
                                </span>
                                {
                                    ((selectedData?.priceForMonthly * 12 - selectedData?.priceForYearly) > 0) &&
                                    <span className={`fs-14 ps-2 text-${(subValue?.type == "yearly") ? "light" : "success"}`}>(Save ₹{selectedData?.priceForMonthly * 12 - selectedData?.priceForYearly})</span>
                                }
                            </label>
                        </div>


                    </Modal.Body>
                    <Modal.Footer className='d-grid' style={{ justifyContent: "normal" }}>
                        <div className='d-grid d-md-flex gap-2'>
                            <Button className='w-100 custom-bg-temp' variant="success" onClick={() => {
                                handleSubscription();
                            }}
                                style={{ cursor: `${(loader) ? "no-drop" : (subValue) ? 'pointer' : 'no-drop'}` }}
                                disabled={(loader) ? true : (subValue) ? false : true}
                            >
                                {(loader) ?
                                    <Spinner animation='border' />
                                    : "Confirm"}
                            </Button>
                            <Button variant='dark' className='w-100' onClick={() => setShow(false)}>Cancel</Button>
                        </div>
                    </Modal.Footer>
                </Modal>
                {/* From Home List Over/End*/}
                {/* <div className='seller-subscription-sub-container-second pb-5'>
                    <Container>
                        <h5 className='fw-semibold mb-3'>Discount examples</h5>
                        <Row className='gy-5 gy-lg-0'>
                            {
                                subscriptionProduct.map((item, index) => {
                                    return (
                                        <Col key={index} className='col-12 col-sm-6 col-md-4 col-lg-3 d-grid'>
                                            <div className='seller-sub-img'>
                                                <img src={item.thumbnail} alt="" />
                                            </div>
                                            <div className='seller-sub-cnt d-grid pt-3'>
                                                <h6 className='fw-semibold fs-14'>{item.title}</h6>
                                                <div className='flex-between-align fs-14'>
                                                    <span>Regular price:</span>
                                                    <span>₹{item.regularPrice}</span>
                                                </div>
                                                <div className='flex-between-align fs-14'>
                                                    <span>Printfuse Premium price::</span>
                                                    <span>₹{item.pPrice}</span>
                                                </div>
                                                <hr className='my-2' />
                                                <div className='flex-between-align fs-14'>
                                                    <span>Savings:</span>
                                                    <span>₹{item.regularPrice - item.pPrice}</span>
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                        <hr className='my-5' />
                        <div className='d-grid'>
                            <h5 className='fw-semibold text-center'>Check out our product catalog with discounted prices</h5>
                            <div className='flex-center-align pt-3'>
                                <Link to={'/category'} className='fw-semibold custom-see-discount-btn'>See discounts</Link>
                            </div>
                        </div>
                    </Container>
                </div> */}

                {/* <div className='seller-subscription-sub-container-third py-5'>
                    <Container>
                        <Row>
                            <Col className='col-12 col-md-6 d-grid'>
                                <div className='flex-center-align'>
                                    <div className='custom-info-img-container'>
                                        <img src={require('../../Assets/Images/5.jpg')} alt="" />
                                    </div>
                                </div>
                            </Col>
                            <Col className='col-12 col-md-6 d-grid pt-3 pt-md-0'>
                                <div className='d-grid gap-1'>
                                    <h5 className='fw-semibold m-0'>Hanna Barlow</h5>
                                    <span className='fs-14 fw-semibold'>Co-founder of MK Studio</span>
                                    <p className='p-color fs-14 fs-sm-12'>
                                        One of the main reasons for not being able to make sales is that the product retail price is too high. Printify Premium plan enables you to have lower retail prices. So you can get more conversions, more sales and more profit. Printify subscription plan is for anyone who is serious about running a successful online store.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div> */}
                {/* <div className='seller-subscription-sub-container-fourth py-5'>
                    <Container>
                        <Row className='px-2'>
                            <Col className='col-12 col-lg-3 px-0 py-4 d-grid align-content-center'>
                                <div className='flex-center-align'>
                                    <div className='custom-coupon-img'>
                                        <img src={require('../../Assets/Images/coupon.png')} alt="" />
                                    </div>
                                </div>
                                <h5 className='fw-semibold text-center custom-heading-text'>Your savings</h5>
                            </Col>
                            <Col className='col-12 col-lg-9 pt-3'>
                                <Row>
                                    <Col className='col-4 border-bottom py-3'>
                                        <h5 className='fw-semibold fs-16 fs-lg-14 fs-sm-12'>Purchase volume</h5>
                                    </Col>
                                    <Col className='col-4 border-bottom py-3'>
                                        <h5 className='fw-semibold fs-16 fs-lg-14 fs-sm-12'>Monthly savings</h5>
                                    </Col>
                                    <Col className='col-4 border-bottom py-3'>
                                        <h5 className='fw-semibold fs-16 fs-lg-14 fs-sm-12'>Yearly savings</h5>
                                    </Col>

                                    <Col className='col-4 border-bottom py-2 py-sm-3'>
                                        <span className='p-color fs-16 fs-lg-14 fs-sm-12 ps-1'>₹750</span>
                                    </Col>
                                    <Col className='col-4 border-bottom py-2 py-sm-3'>
                                        <span className='p-color fs-16 fs-lg-14 fs-sm-12 ps-1'>₹150</span>
                                    </Col>
                                    <Col className='col-4 border-bottom py-2 py-sm-3'>
                                        <span className='p-color fs-16 fs-lg-14 fs-sm-12 ps-1'>₹1800</span>
                                    </Col>

                                    <Col className='col-4 border-bottom py-2 py-sm-3'>
                                        <span className='p-color fs-16 fs-lg-14 fs-sm-12 ps-1'>₹1500</span>
                                    </Col>
                                    <Col className='col-4 border-bottom py-2 py-sm-3'>
                                        <span className='p-color fs-16 fs-lg-14 fs-sm-12 ps-1'>₹300</span>
                                    </Col>
                                    <Col className='col-4 border-bottom py-2 py-sm-3'>
                                        <span className='p-color fs-16 fs-lg-14 fs-sm-12 ps-1'>₹3600</span>
                                    </Col>

                                    <Col className='col-4 border-bottom py-2 py-sm-3'>
                                        <span className='p-color fs-16 fs-lg-14 fs-sm-12 ps-1'>₹5000</span>
                                    </Col>
                                    <Col className='col-4 border-bottom py-2 py-sm-3'>
                                        <span className='p-color fs-16 fs-lg-14 fs-sm-12 ps-1'>₹1000</span>
                                    </Col>
                                    <Col className='col-4 border-bottom py-2 py-sm-3'>
                                        <span className='p-color fs-16 fs-lg-14 fs-sm-12 ps-1'>₹12000</span>
                                    </Col>

                                    <Col className='col-4 border-bottom py-2 py-sm-3'>
                                        <span className='p-color fs-16 fs-lg-14 fs-sm-12 ps-1'>₹10,000</span>
                                    </Col>
                                    <Col className='col-4 border-bottom py-2 py-sm-3'>
                                        <span className='p-color fs-16 fs-lg-14 fs-sm-12 ps-1'>₹2000</span>
                                    </Col>
                                    <Col className='col-4 border-bottom py-2 py-sm-3'>
                                        <span className='p-color fs-16 fs-lg-14 fs-sm-12 ps-1'>₹24,000</span>
                                    </Col>

                                    <Col className='col-4 border-bottom py-2 py-sm-3'>
                                        <span className='p-color fs-16 fs-lg-14 fs-sm-12 ps-1'>₹20,000</span>
                                    </Col>
                                    <Col className='col-4 border-bottom py-2 py-sm-3'>
                                        <span className='p-color fs-16 fs-lg-14 fs-sm-12 ps-1'>₹4000</span>
                                    </Col>
                                    <Col className='col-4 border-bottom py-2 py-sm-3'>
                                        <span className='p-color fs-16 fs-lg-14 fs-sm-12 ps-1'>₹48,000</span>
                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div> */}
            </Container >
        </div >
    )
}

export default SellerSubscription