import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Container, Modal, Row, Spinner, Tab, Tabs } from 'react-bootstrap'
import { CiLocationOn } from 'react-icons/ci';
import { IoStorefrontOutline } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { pattern } from '../../Config/Constant';
import { SiReacthookform } from 'react-icons/si';
import { MdKeyboardDoubleArrowRight } from 'react-icons/md';
import { frontEnd_API, header, storage, token } from '../../Config/Config';
import axios from 'axios';
import { addStore, updateStore } from '../../Store/Slices/StoreSlice';
import { RiShareBoxLine } from 'react-icons/ri';
import FormComponent from '../../Components/FormComponent';
import moment from 'moment/moment';
import { GiTakeMyMoney } from "react-icons/gi";
import Swal from 'sweetalert2';
import Loader from '../../Components/Loader';
import { Tooltip } from 'react-tooltip';
import { Input } from 'reactstrap';
import CustomDomainSteps from '../../Components/CustomDomainSteps';
import { FaFacebookSquare, FaTags } from "react-icons/fa";
import { FcApproval, FcGoogle } from "react-icons/fc";

function SellerStoreSetting() {
    const activeStore = useSelector((state) => state.store.value)
    const sellerDetail = useSelector((state) => state?.user?.value)
    const [customDomain, setCustomDomain] = useState(activeStore?.customDomain ?? '');
    const [customDomainError, setCustomDomainError] = useState('');

    const [storeData, setStoreData] = useState({});
    const [stateDataShop, setStateDataShop] = useState([]);
    const [stateData, setStateData] = useState([]);
    const [isChecked, setIsChecked] = useState(false);
    const dispatch = useDispatch();
    const [err, setErr] = useState({});
    const [show, setShow] = useState(false);

    const [themeValue, setThemeValue] = useState((activeStore?.currentTheme) ?? 'theme-one');
    const [newLogo, setNewLogo] = useState(null);
    const [favicon, setFavicon] = useState(null);
    const [loader, setLoader] = useState({
        store: false,
        theme: false,
        social: false,
        coupon: false,
        page: false,
        banner: false
    });
    const [tabHandler, setTabHandler] = useState("updateDetails");
    const [returnToStore, setReturnToStore] = useState(activeStore?.isReturnToStore ?? 0);

    useEffect(() => {
        axios.get(frontEnd_API.getGlobal, header)
            .then((res) => {
                setStateDataShop(res?.data?.data);
                setStateData(res?.data?.data);
            })
            .catch((e) => {
                console.log(e);
            })
    }, [])

    const getSingleStore = async () => {
        setLoader({ ...loader, store: true })
        try {
            const { data } = await axios.get(`${frontEnd_API.getSingleStore}/${activeStore.id}`, header)
            setStoreData(data?.data);
            dispatch(addStore(data?.data));
            setLoader({ ...loader, store: false })
        }
        catch (e) {
            console.log(e);
            setLoader({ ...loader, store: false })
        }
    }

    useEffect(() => {
        getSingleStore();
    }, [activeStore.id, dispatch])

    const handleInputChange = (val) => {

        delete err[val?.name];
        const { validation } = val;

        // For Chacking Required value
        if (val?.pattern || val?.required) {
            if (val?.required && val?.value?.length <= 0) {
                err[val?.name] = `${val?.name} is required`
            }

            if (val?.pattern && val?.value?.length > 0) {
                const patternCheck = new RegExp(val?.pattern);
                if (!patternCheck.test(val?.value))
                    err[val?.name] = `Invalid pattern for ${val?.name}`;
            }
        }

        const { name, value } = val;

        setStoreData((prevData) => ({
            ...prevData,
            [name]: value
        }))

        if (name == "shortDescription") {
            if (value && value?.toString().length > 300) {
                setErr((prevData) => ({
                    ...prevData,
                    [name]: "Enter max 300 character in short description"
                }))
            }
        }

        if (val?.file) {
            const fileType = val?.file?.type;
            const allowedTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/webp', 'image/svg+xml'];

            if (!allowedTypes.includes(fileType)) {
                setErr({ logo: 'Only PNG, JPG, JPEG, WEBP, and SVG files are allowed.' });

                if (name == 'logo') {
                    setNewLogo(null)
                }

                setStoreData((prevData) => ({
                    ...prevData,
                    [name]: null
                }))
            } else if (validation) {

                console.log("validation::", validation);
                console.log("val?.all::", val?.all);

                const file = val?.all?.target?.files[0];
                const img = new Image();
                const reader = new FileReader();

                reader.onload = (e) => {
                    img.src = e.target.result;


                    img.onload = () => {
                        console.log("img.width", img.width, img.height)
                        if (img.width > validation?.width || img.height > validation?.height) {
                            setErr({ favicon: `Logo must be less than ${validation?.height} x ${validation?.width} pixels.` });

                            setStoreData((prevData) => ({
                                ...prevData,
                                [name]: null
                            }))
                        } else {
                            if (name == 'favicon') {
                                setFavicon(val?.file)
                            }

                            setStoreData((prevData) => ({
                                ...prevData,
                                [name]: val?.file
                            }))
                        }
                    };
                };

                reader.readAsDataURL(file);

            } else {
                if (name == 'logo') {
                    setNewLogo(val?.value)
                }

                setStoreData((prevData) => ({
                    ...prevData,
                    [name]: val?.file
                }))
            }
        }
    }

    const manageSameAddress = (obj = {}) => {
        if (obj?.value !== storeData[obj?.compareKey])
            setIsChecked(false)
    }

    const handleAddressChange = () => {
        if (
            storeData?.addressFirstLine &&
            storeData?.addressSecondLine &&
            storeData?.state &&
            storeData?.city &&
            storeData?.pinCode &&
            storeData?.mobileNumber &&
            storeData?.emailAddress
        ) {
            setIsChecked(!isChecked);
            if (!isChecked) {
                setStoreData((prevData) => ({
                    ...prevData,
                    billingAddressFirstLine: storeData?.addressFirstLine,
                    billingAddressSecondLine: storeData?.addressSecondLine,
                    billingState: storeData?.state,
                    billingCity: storeData?.city,
                    billingPinCode: storeData?.pinCode,
                    billingMobileNumber: storeData?.mobileNumber,
                    billingEmailAddress: storeData?.emailAddress
                }))
            }
            else {
                setStoreData((prevData) => ({
                    ...prevData,
                    billingAddressFirstLine: '',
                    billingAddressSecondLine: '',
                    billingState: '',
                    billingCity: '',
                    billingPinCode: '',
                    billingMobileNumber: '',
                    billingEmailAddress: ''
                }))
            }
        } else {
            toast("Fill Shop Address First !")
            setIsChecked(false);
        }

    }

    const submitStoreData = () => {

        console.log("((storeData?.logo?.name?.length > 0) || (storeData?.favicon?.name?.length > 0)) ? 'multipart/form-data' : 'application/json'::", ((storeData?.logo?.name?.length > 0) || (storeData?.favicon?.name?.length > 0)) ? 'multipart/form-data' : 'application/json');
        Swal.fire({
            title: `Are you sure to update store details?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#04ac84',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (Object.values(err).length === 0) {
                    // Add Country in data
                    setLoader({ ...loader, store: true })
                    const addCountry = {
                        ...storeData,
                        country: 'India',
                        billingCountry: 'India',
                        storeType: activeStore?.storeType
                    };
                    delete addCountry.storeCode;
                    delete addCountry.storeBaseURL;
                    delete addCountry.domain;
                    addCountry.brandingStatus = parseInt(addCountry?.brandingStatus) > 0 ? 1 : 0

                    axios.put(`${frontEnd_API.postStore}/${addCountry.id}`, addCountry, {
                        headers: {
                            'token': token,
                            'Content-Type': ((storeData?.logo?.name?.length > 0) || (storeData?.favicon?.name?.length > 0)) ? 'multipart/form-data' : 'application/json'
                        }
                    })
                        .then((res) => {
                            getSingleStore();
                            setStoreData(res?.data?.data);
                            setErr({});
                            setLoader({ ...loader, store: false })
                            Swal.fire('Store details updated successfully!', '', 'success');
                        })
                        .catch((e) => {
                            console.log("error::", e);
                            setErr(e?.response?.data?.data)
                            setLoader({ ...loader, store: false })
                            Swal.fire(e?.response?.data?.message, "", 'error');
                        })
                }
            }
        });
    }

    useEffect(() => {
        if (storeData?.billingAddressFirstLine ||
            storeData?.billingAddressSecondLine ||
            storeData?.billingState ||
            storeData?.billingCity ||
            storeData?.billingPinCode ||
            storeData?.billingMobileNumber ||
            storeData?.billingEmailAddress
        ) {
            if (
                storeData?.billingAddressFirstLine === storeData?.addressFirstLine &&
                storeData?.billingAddressSecondLine === storeData?.addressSecondLine &&
                storeData?.billingState === storeData?.state &&
                storeData?.billingCity === storeData?.city &&
                storeData?.billingPinCode === storeData?.pinCode &&
                storeData?.billingMobileNumber === storeData?.mobileNumber &&
                storeData?.billingEmailAddress === storeData?.emailAddress
            ) {
                setIsChecked(true);
            }
            else {
                setIsChecked(false);
            }
        }
    }, [storeData]);

    // Change Theme Code

    const themeOption = [
        {
            image: require('../../Assets/Images/theme-1.png'),
            value: 'theme-one'
        },
        {
            image: require('../../Assets/Images/theme-2.png'),
            value: 'theme-two'
        },
        {
            image: require('../../Assets/Images/theme-3.png'),
            value: 'theme-three'
        },
        {
            image: require('../../Assets/Images/theme-4.png'),
            value: 'theme-four'
        },
        {
            image: require('../../Assets/Images/theme-5.png'),
            value: 'theme-five'
        },
    ]

    const submitThemeData = async () => {
        setLoader({ ...loader, theme: true })
        try {
            const { data } = await axios.put(`${frontEnd_API.themeChange}/${activeStore.id}`, { currentTheme: themeValue }, header)
            dispatch(updateStore(data?.data))
            Swal.fire(
                'Store theme updated successfully!',
                '',
                'success'
            );
            setLoader({ ...loader, theme: false })
        }
        catch (e) {
            console.log("err::", e);
            setLoader({ ...loader, theme: false })
        }
    }

    // Update Social Media

    const socialLinks = [
        {
            value: 'whatsapp',
            label: 'WhatsApp'
        },
        {
            value: 'facebook',
            label: 'Facebook'
        },
        {
            value: 'instagram',
            label: 'Instagram'
        },
        {
            value: 'linkedin',
            label: 'Linkedin'
        },
        {
            value: 'twitter-x',
            label: 'Twitter'
        },
        {
            value: 'pinterest',
            label: 'Pinterest'
        }
    ];

    const [listData, setListData] = useState([]);

    const callSocialList = async () => {
        setLoader({ ...loader, social: true })
        try {
            const { data } = await axios.put(frontEnd_API.snapStore, {}, header)
            setListData(data?.data?.non_field_error ? [] : data?.data);
            setLoader({ ...loader, social: false })
        }
        catch (e) {
            console.log("err::", e);
            setListData([]);
            setLoader({ ...loader, social: false })
        }
        setUpdateSocialLink();
        setUpdateSocialLinkId();
    }

    const deleteLink = async (item) => {
        Swal.fire({
            title: `Are you sure to delete ${item.icon}'s data?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#04ac84',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoader({ ...loader, social: true })
                try {
                    const { data } = await axios.delete(`${frontEnd_API.snapStore}/${item.id}`, header)
                    console.log(data);
                    Swal.fire(
                        'Data Deleted successfully!',
                        '',
                        'success'
                    );
                    callSocialList();
                    setLoader({ ...loader, social: false })
                }
                catch (e) {
                    console.log(e);
                    setLoader({ ...loader, social: false })
                }
            }
        });
    }

    const handleLinkStatus = async (item) => {
        try {
            const { data } = await axios.put(`${frontEnd_API.snapStore}/status/${item.id}`,
                { status: (item.status === 1) ? 0 : 1 },
                header
            )
            toast(`${socialLinks?.filter(e => item?.icon == e?.value)[0]?.label}'s link is ${(data.data.status === 0) ? 'inactive' : 'active'} !`, { className: (data.data.status === 0) ? 'text-danger' : 'text-success' })
            callSocialList();
        }
        catch (e) {
            console.log(e);
        }
    }

    const [updateSocialLink, setUpdateSocialLink] = useState();
    const [updateSocialLinkId, setUpdateSocialLinkId] = useState();

    const handleUpdateLink = (item) => {
        // const check = 
        setUpdateSocialLink({ 'icon': item.icon, 'link': item.link })
        setUpdateSocialLinkId(item.id);
        return item.id;
    }


    // coupon section ------------
    const [couponListData, setCouponListData] = useState([]);
    const callCouponList = async () => {
        setLoader({ ...loader, coupon: true })
        try {
            const { data } = await axios.put(frontEnd_API.coupon, {}, header)
            setCouponListData(data?.data?.non_field_error ? [] : data?.data);
            setLoader({ ...loader, coupon: false })
        }
        catch (e) {
            console.log("err::", e);
            setCouponListData([]);
            setLoader({ ...loader, coupon: false })
        }
        setUpdateCoupon();
        setUpdateCouponId();
    }

    const deleteCoupon = async (item) => {
        Swal.fire({
            title: `Are you sure to delete coupon data?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#04ac84',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoader({ ...loader, coupon: true })
                try {
                    const { data } = await axios.delete(`${frontEnd_API.coupon}/${item.id}`, header)
                    Swal.fire(
                        'Data Deleted successfully!',
                        '',
                        'success'
                    );
                    callCouponList();
                    setLoader({ ...loader, coupon: false })
                }
                catch (e) {
                    console.log(e);
                    setLoader({ ...loader, coupon: false })
                }
            }
        });
    }

    const handleCouponStatus = async (item) => {
        try {
            const { data } = await axios.put(`${frontEnd_API.coupon}/status/${item.id}`,
                { status: (item.status === 1) ? 0 : 1 },
                header
            )
            toast(`Coupon code is ${(item.status === 1) ? 'inactive' : 'active'} !`, { className: (item.status === 1) ? 'text-danger' : 'text-success' })
            callCouponList();
        }
        catch (e) {
            console.log(e);
        }
    }

    const [updateCoupon, setUpdateCoupon] = useState();
    const [updateCouponId, setUpdateCouponId] = useState();

    const handleUpdateCoupon = (item) => {
        const obj = item

        delete obj?.status
        delete obj?.totalUsed

        setUpdateCoupon({
            ...obj,
            expireDate: moment(item.expireDate)?.format('YYYY-MM-DD')
        })
        setUpdateCouponId(obj.id);
        return item.id;
    }

    // ------------


    // page section ------------
    const [pageListData, setPageListData] = useState([]);
    const callPageList = async () => {
        setLoader({ ...loader, page: true })
        try {
            const { data } = await axios.put(frontEnd_API.page, {}, header)
            setPageListData(data?.data?.non_field_error ? [] : data?.data)
            setLoader({ ...loader, page: false })
        }
        catch (e) {
            console.log("err::", e);
            setPageListData([]);
            setLoader({ ...loader, page: false })
        }
        setUpdatePage();
        setUpdatePageId();
    }

    const deletePage = async (item) => {
        Swal.fire({
            title: `Are you sure to delete ${item?.name}'s data?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#04ac84',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoader({ ...loader, page: true })
                try {
                    const { data } = await axios.delete(`${frontEnd_API.page}/${item.id}`, header)
                    Swal.fire(
                        'Data Deleted successfully!',
                        '',
                        'success'
                    );
                    callPageList();
                    setLoader({ ...loader, page: false })
                }
                catch (e) {
                    console.log(e);
                    setLoader({ ...loader, page: false })
                }
            }
        });
    }

    const handlePageStatus = async (item) => {
        try {
            const { data } = await axios.put(`${frontEnd_API.page}/status/${item.id}`,
                { status: (item.status === 1) ? 0 : 1 },
                header
            )
            toast(`page is now ${(data.data.status === 0) ? 'inactive' : 'active'} !`, { className: (data.data.status === 0) ? 'text-danger' : 'text-success' })
            callPageList();
        }
        catch (e) {
            console.log(e);
        }
    }

    const [updatePage, setUpdatePage] = useState();
    const [updatePageId, setUpdatePageId] = useState();

    const handleUpdatePage = (item) => {
        // const check = 
        setUpdatePage({ 'name': item.name, 'description': item.description })
        setUpdatePageId(item.id);
        return item.id;
    }
    // ------------

    const [storeCategoryData, setStoreCategoryData] = useState([]);
    const [bannerList, setBannerList] = useState([]);

    const callBannerList = async () => {
        setLoader({ ...loader, banner: true })
        try {
            const { data } = await axios.put(frontEnd_API.banner, {}, header)
            setBannerList(data?.data?.non_field_error ? [] : data?.data)
            setLoader({ ...loader, banner: false })
        }
        catch (e) {
            console.log("err::", e);
            setBannerList([]);
        }
        setUpdateBanner();
        setUpdateBannerId();
    }

    const deleteBanner = async (item) => {
        Swal.fire({
            title: `Are you sure to delete ${item?.name}'s data?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#04ac84',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoader({ ...loader, banner: true })
                try {
                    const { data } = await axios.delete(`${frontEnd_API.banner}/${item.id}`, header)
                    Swal.fire(
                        'Data Deleted successfully!',
                        '',
                        'success'
                    );
                    callBannerList();
                    setLoader({ ...loader, banner: false })
                }
                catch (e) {
                    console.log(e);
                    setLoader({ ...loader, banner: false })
                }
            }
        });
    }

    const handleBannerStatus = async (item) => {
        try {
            const { data } = await axios.put(`${frontEnd_API.banner}/status/${item.id}`,
                { status: (item.status === 1) ? 0 : 1 },
                header
            )
            toast(`${item.name} is ${(data.data.status === 0) ? 'inactive' : 'active'} !`, { className: (data.data.status === 0) ? 'text-danger' : 'text-success' })
            callBannerList();
        }
        catch (e) {
            console.log(e);
        }
    }

    const [updateBanner, setUpdateBanner] = useState();
    const [updateBannerId, setUpdateBannerId] = useState();

    const handleUpdateBanner = (item) => {
        setUpdateBanner({ 'relatedId': item.relatedId, 'relatedName': item.relatedName, 'name': item.name, 'thumbnail': item.thumbnail })
        setUpdateBannerId(item.id);
        return item.id;
    }

    // function for get store category dropdown data
    const fetchStoreCategoryDropDown = async () => {
        try {
            const { data } = await axios.get(`${frontEnd_API.home}/storecategory`, header)
            setStoreCategoryData([...(data?.data ?? [])])
        }
        catch (e) {
            console.log(e);
            setStoreCategoryData([])
        }
    }

    useEffect(() => {
        callSocialList();
        callCouponList();
        callPageList();
        callBannerList();
        fetchStoreCategoryDropDown()
    }, []);

    const submitReturnDetails = async () => {
        try {
            const response = await axios.put(`${frontEnd_API?.storeReturnDetails}/${activeStore?.id}`, { isReturnToStore: returnToStore }, header)
            if (response?.status == 200) {
                dispatch(updateStore(response?.data?.data))
                toast("Return Deatils Update successfully!")
            }
        }
        catch (e) {
            console.log("Err::", e);
        }
    }

    // function for manage store return order module -----
    const manageStoreReturnOrderStatus = async (status = false) => {
        const response = await axios.put(`${frontEnd_API?.storeReturnDetails}/${activeStore?.id}`, {
            isReturnToStore: status ? 1 : 0
        }, header)

        localStorage?.setItem(storage?.store, JSON.stringify({
            ...activeStore,
            isReturnToStore: response?.data?.data?.isReturnToStore ?? 0
        }))
        setReturnToStore(response?.data?.data?.isReturnToStore > 0 ? 1 : 0)
    }
    // -----

    // function for manage custom domain module -----
    const [domainFormSubmitStatus, setDomainFormSubmitStatus] = useState(false)
    const manageCustomDomainDetail = async (code = 0) => {
        setDomainFormSubmitStatus(true)
        try {
            const { data, status } = await axios.put(`${frontEnd_API?.storeCustomDomain}/${activeStore?.id}`, { customDomain: code >= 0 ? customDomain : '' }, header)
            setCustomDomainError('')
            console.log("data ::: 681 :: ", data?.data)
            dispatch(updateStore(data?.data))
            setCustomDomain(code >= 0 ? customDomain : '')
            Swal.fire(code >= 0 ? data?.message : 'Custom domain removed successfully', '', 'success');
            setDomainFormSubmitStatus(false)
            getSingleStore()
            setShow(true)
            setTimeout(() => {
                setShow(false)
            }, 3000)
        }
        catch (e) {
            console.log("Err::", e);
            setCustomDomainError(e?.response?.data?.message)
            setDomainFormSubmitStatus(false)
        }
    }
    // -----

    return (
        <div className='custom-store-setting'>
            <Container className='pb-5'>
                <div className='flex-between-align'>
                    <h2 className='m-0 fw-semibold py-4'>Store Setting</h2>
                    <Link
                        to={`https://${activeStore?.storeType == "SHOPIFY"
                            ? activeStore?.shopifyStoreUrl
                            : sellerDetail?.expired
                                ? activeStore?.domain
                                : activeStore?.customDomain
                                ?? activeStore?.domain}`}
                        target='_blanck'
                        className='custom-store-btn fw-semibold flex-center-align gap-1'
                    >
                        <span className='d-none d-sm-block'>Open Store</span>
                        <RiShareBoxLine className='flex-center-align' />
                    </Link>
                </div>
                <div className='seller-store-setting-container p-2 p-sm-3'>
                    <Tabs
                        defaultActiveKey="updateDetails"
                        id="fill-tab-example"
                        className="mb-3 custom-tabs pt-2"
                        activeKey={tabHandler}
                        onSelect={(e) => setTabHandler(e)}
                        fill
                    >
                        <Tab eventKey="updateDetails" title="Update Details" className='p-2 p-sm-3'>
                            <Container className='custom-store-detail container d-grid gap-4 gap-sm-5 position-relative'>
                                {/* <div className='d-grid align-content-center position-absolute w-100 top-0'>
                                    <div className='w-100 aspect-4-1 aspect-md-2-1'>
                                        <Loader />
                                    </div>
                                </div> */}
                                {
                                    loader?.store &&
                                    <div className='position-absolute w-100 h-100'>
                                        <div
                                            className='position-sticky top-0 start-0 w-100 flex-center-align'
                                            style={{
                                                backgroundColor: 'rgba(255,255,255,0.85)',
                                                zIndex: "9999",
                                                height: "100vh"
                                            }}
                                        >
                                            <div className='d-grid text-center text-success gap-1'>
                                                <div className='w-100 aspect-2-1 aspect-md-2-1'>
                                                    <Loader />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {/* Store Details */}
                                <Row className='custom-contain-align-center py-3'>
                                    <h3 className='fw-semibold text-center py-3'>Store details</h3>
                                    <Col className='col-0 d-none d-md-block col-md-3 custom-store-image'>
                                        {/* <IoStorefrontOutline /> */}
                                        <div className='logo-holder aspect-5-2 w-100'>
                                            <img src={require('../../Assets/Images/store.png')} className='w-100 h-100 object-contain' alt="" />
                                        </div>
                                    </Col>
                                    <Col className='col-12 col-md-9 d-grid gap-1 gap-sm-2 gap-lg-3 custom-store-detail-content'>
                                        <div className='d-grid'>
                                            <label htmlFor="name">Store Name</label>
                                            <input
                                                type="text"
                                                value={storeData?.name}
                                                name='name'
                                                id='name'
                                                className={(err?.name) && 'border-red'}
                                                onChange={(e) => {
                                                    const { name, value } = e.target;
                                                    handleInputChange({ name, value });
                                                }}
                                                required={true}
                                                autoComplete='off'
                                            />
                                            <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height flex-between-align'>{err?.name ?? ''}</span>
                                        </div>
                                        <div className='d-grid'>
                                            <label htmlFor="shortDescription">Short Description <span className='fs-12'>(Optional)</span></label>
                                            <textarea
                                                name="shortDescription"
                                                id="shortDescription"
                                                rows={3}
                                                value={storeData?.shortDescription}
                                                onChange={(e) => handleInputChange({ name: e.target.name, value: e.target.value })}
                                                autoComplete='off'>
                                            </textarea>
                                            <div className='d-flex w-100 justify-content-between'>
                                                <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height'>{err?.shortDescription}</span>
                                                <span className='fs-12 text-secondary text-end fw-bold fs-sm-10 custom-span-height'>
                                                    {storeData?.shortDescription?.toString().length ?? 0}/300
                                                </span>
                                            </div>
                                        </div>
                                        <div className='flex-start-align gap-4 gap-md-5'>
                                            <div className='d-grid custom-img-selector'>
                                                <label htmlFor="logo" className='width-fit pointer'>
                                                    <span>Store Logo</span>
                                                    {
                                                        (storeData?.logo)
                                                            ? <div className='width-fit'>
                                                                <img src={
                                                                    newLogo
                                                                        ? URL.createObjectURL(newLogo)
                                                                        : storeData?.logo
                                                                } alt="" style={{ maxHeight: '100px' }} />
                                                            </div>
                                                            : <div>
                                                                <i className="bi bi-image fs-40" />
                                                            </div>
                                                    }
                                                </label>
                                                <input
                                                    type="file"
                                                    // value={storeData?.logo}
                                                    name='logo'
                                                    id='logo'
                                                    className={(err?.logo) && 'border-red'}
                                                    onChange={(e) => {
                                                        handleInputChange({ name: 'logo', file: e.target.files[0] });
                                                        setNewLogo(e.target.files[0])
                                                    }}
                                                    required={true}
                                                    accept=".png, .jpg, .jpeg, .webp, .svg"
                                                    autoComplete='off' />
                                                <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height flex-between-align'>{err?.logo ?? ''}</span>
                                            </div>
                                            <div className='d-grid custom-img-selector width-fit'>
                                                <label htmlFor="favicon" className='width-fit pointer'>
                                                    <span className='width-fit'>Store Favicon</span>
                                                    {
                                                        (storeData?.favicon)
                                                            ? <div>
                                                                <img src={
                                                                    favicon
                                                                        ? URL.createObjectURL(favicon)
                                                                        : storeData?.favicon
                                                                } alt="" style={{ maxHeight: '100px' }} />
                                                            </div>
                                                            : <div>
                                                                <i className={`bi bi-image fs-40 half-border-rad ${err?.favicon ? "border border-danger" : ""}`} />
                                                            </div>
                                                    }
                                                </label>
                                                <input
                                                    type="file"
                                                    name='favicon'
                                                    id='favicon'
                                                    className={(err?.favicon) && 'border-red'}
                                                    onChange={(e) => {
                                                        handleInputChange({ name: 'favicon', file: e.target.files[0], validation: { height: 256, width: 256 }, all: e })
                                                        setFavicon(e.target.files[0])
                                                    }}
                                                    required={true}
                                                    accept=".png, .jpg, .jpeg, .webp, .svg"
                                                    autoComplete='off' />
                                                <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height flex-between-align'>{err?.favicon ?? ''}</span>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                {/* Meta Details */}
                                <Row className='custom-contain-align-center py-3'>
                                    <h3 className='fw-semibold text-center py-3'>Meta tag</h3>
                                    <Col className='col-0 d-none d-md-block col-md-3 custom-store-image'>
                                        {/* <FaTags /> */}
                                        <div className='logo-holder aspect-5-2 w-100'>
                                            <img src={require('../../Assets/Images/meta-tag.png')} className='w-100 h-100 object-contain' alt="" />
                                        </div>
                                    </Col>
                                    <Col className='col-12 col-md-9 d-grid gap-1 gap-sm-2 gap-lg-3 custom-store-detail-content'>
                                        <div className='d-grid'>
                                            <label htmlFor="metaTitle">Meta Title</label>
                                            <input
                                                type="text"
                                                value={storeData?.metaTitle}
                                                name='metaTitle'
                                                id='metaTitle'
                                                className={(err?.metaTitle) && 'border-red'}
                                                onChange={(e) => {
                                                    const { name, value } = e.target;
                                                    handleInputChange({ name, value });
                                                }}
                                                required={true}
                                                autoComplete='off'
                                                placeholder='add meta title here...'
                                            />
                                            <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height flex-between-align'>{err?.metaTitle ?? ''}</span>
                                        </div>
                                        <div className='d-grid'>
                                            <label htmlFor="metaDescription">Meta Description</label>
                                            <textarea
                                                name="metaDescription"
                                                id="metaDescription"
                                                rows={2}
                                                value={storeData?.metaDescription}
                                                onChange={(e) => handleInputChange({ name: e.target.name, value: e.target.value })}
                                                className={(err?.metaDescription) && 'border-red'}
                                                autoComplete='off'
                                                placeholder='add meta description here...'
                                            >
                                            </textarea>
                                            <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height'>{err?.metaDescription ?? ''}</span>
                                        </div>
                                        {/* <div className='d-grid custom-img-selector width-fit'>
                                            <label htmlFor="favicon" className='width-fit'>
                                                <span className='width-fit'>Store Favicon</span>
                                                {
                                                    (storeData?.favicon)
                                                        ? <div>
                                                            <img src={
                                                                favicon
                                                                    ? URL.createObjectURL(favicon)
                                                                    : storeData?.favicon
                                                            } alt="" style={{ maxHeight: '100px' }} />
                                                        </div>
                                                        : <div>
                                                            <i className={`bi bi-image fs-40 half-border-rad ${err?.favicon ? "border border-danger" : ""}`} />
                                                        </div>
                                                }
                                            </label>
                                            <input
                                                type="file"
                                                name='favicon'
                                                id='favicon'
                                                className={(err?.favicon && !storeData?.favicon) && 'border-red'}
                                                onChange={(e) => {
                                                    handleInputChange({ name: 'favicon', file: e.target.files[0], validation: { height: 256, width: 256 }, all: e })
                                                    setFavicon(e.target.files[0])
                                                }}
                                                required={true}
                                                accept=".png, .jpg, .jpeg, .webp, .svg"
                                                autoComplete='off' />
                                            <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height flex-between-align'>{err?.favicon}</span>
                                        </div> */}
                                    </Col>
                                </Row>

                                {/* Google Tags and Facebook Pixel */}
                                <Row className='custom-contain-align-center py-3'>
                                    <h3 className='fw-semibold text-center py-3'>Google Tag</h3>
                                    <Col className='col-0 d-none d-md-block col-md-3 custom-store-image'>
                                        {/* <FcGoogle /> */}
                                        <div className='logo-holder aspect-5-2 w-100'>
                                            <img src={require('../../Assets/Images/gtag-logo.png')} className='w-100 h-100 object-contain' alt="" />
                                        </div>
                                    </Col>
                                    <Col className='col-12 col-md-9 d-grid gap-1 gap-sm-2 gap-lg-3 custom-store-detail-content'>
                                        <div className='d-grid'>
                                            <label htmlFor="googleTag">Kindly insert your google tag below</label>
                                            <textarea
                                                name="googleTag"
                                                id="googleTag"
                                                rows={2}
                                                value={storeData?.googleTag}
                                                onChange={(e) => handleInputChange({ name: e.target.name, value: e.target.value })}
                                                className={(err?.googleTag) && 'border-red'}
                                                autoComplete='off'
                                                placeholder='Ex. GTM-ABC123'
                                            >
                                            </textarea>
                                            <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height'>
                                                {err?.googleTag}
                                            </span>
                                        </div>
                                        {/* <div className='d-grid'>
                                            <label htmlFor="faceBookPixel">Facebook Pixel</label>
                                            <textarea
                                                name="faceBookPixel"
                                                id="faceBookPixel"
                                                rows={2}
                                                value={storeData?.faceBookPixel}
                                                onChange={(e) => handleInputChange({ name: e.target.name, value: e.target.value })}
                                                className={(err?.faceBookPixel) && 'border-red'}
                                                autoComplete='off'
                                                placeholder='Ex. 123456789012345'
                                            >
                                            </textarea>
                                            <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height'>
                                                {err?.faceBookPixel}
                                            </span>
                                        </div> */}
                                    </Col>
                                </Row>

                                {/* Is COD available */}
                                {
                                    activeStore?.storeType == "SNAPSTORE" &&
                                    <Row className='custom-contain-align-center py-3'>
                                        <h3 className='fw-semibold text-center py-3'>COD Option</h3>
                                        <Col className='col-0 d-none d-md-block col-md-3 custom-store-image'>
                                            {/* <GiTakeMyMoney /> */}
                                            <div className='logo-holder aspect-5-2 w-100'>
                                                <img src={require('../../Assets/Images/cash-on-delivery.png')} className='w-100 h-100 object-contain' alt="" />
                                            </div>
                                        </Col>
                                        <Col className='col-12 col-md-9 d-grid gap-1 gap-sm-2 gap-lg-3'>
                                            <div className='d-grid d-sm-flex align-items-center check-holder gap-2'>
                                                <input
                                                    type="checkbox"
                                                    name='isCodAvailable'
                                                    id='isCodAvailable'
                                                    checked={storeData?.isCodAvailable == 1 ? true : false}
                                                    className={`${(err?.isCodAvailable) && 'border-red'} ui-checkbox`}
                                                    onChange={(e) => {
                                                        setStoreData((prevData) => ({
                                                            ...prevData,
                                                            isCodAvailable: e.target.checked ? 1 : 0
                                                        }))
                                                    }}
                                                    autoComplete='off' />
                                                <label htmlFor="isCodAvailable" className='fs-16 fs-sm-12'>Select this checkbox to enable Cash on Delivery (COD) as a payment option for your customers on Snapstore.</label>
                                            </div>
                                        </Col>
                                    </Row>
                                }

                                {/* Shop Address Details */}
                                <Row className='custom-contain-align-center py-3'>
                                    <h3 className='fw-semibold text-center py-3 py-sm-4'>Communication Address</h3>
                                    <Col className='d-none d-md-block col-md-3 custom-store-image'>
                                        {/* <CiLocationOn className='mt-4' /> */}
                                        <div className='logo-holder aspect-5-2 w-100'>
                                            <img src={require('../../Assets/Images/store-address.png')} className='w-100 h-100 object-contain' alt="" />
                                        </div>
                                    </Col>
                                    <Col className='col-12 col-md-9 custom-store-detail-content d-grid gap-3'>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6 d-grid'>
                                                <label htmlFor="addressFirstLine">Address First Line</label>
                                                <textarea
                                                    name="addressFirstLine"
                                                    id="addressFirstLine"
                                                    rows={2}
                                                    className={(err?.addressFirstLine) && 'border-red'}
                                                    value={storeData?.addressFirstLine}
                                                    autoComplete='off'
                                                    onChange={(e) => handleInputChange({ name: e.target.name, value: e.target.value })}
                                                    placeholder="Ex. 'Shop no., Building name'" ></textarea>
                                                <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height'>{err?.addressFirstLine ?? ''}</span>
                                            </div>
                                            <div className='col-12 col-sm-6 d-grid'>
                                                <label htmlFor="addressSecondLine">Address Second Line</label>
                                                <textarea
                                                    name="addressSecondLine"
                                                    id="addressSecondLine"
                                                    rows={2}
                                                    className={(err?.addressSecondLine) && 'border-red'}
                                                    value={storeData?.addressSecondLine}
                                                    autoComplete='off'
                                                    onChange={(e) => handleInputChange({ name: e.target.name, value: e.target.value })}
                                                    placeholder="Ex. 'Landmark'"></textarea>
                                                <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height'>{err?.addressSecondLine ?? ''}</span>
                                            </div>
                                        </div>
                                        <div className='row d-flex'>
                                            <div className='col-6 col-sm-4 custom-over-hidden d-grid'>
                                                <label htmlFor="state">State</label>
                                                <select
                                                    value={storeData?.state}
                                                    name='state'
                                                    id='state'
                                                    className={`text-capitalize ${(err?.state) && 'border-red'}`}
                                                    autoComplete='off'
                                                    onChange={(e) => handleInputChange({ name: e.target.name, value: e.target.value })}
                                                >
                                                    <option value={0}>Select</option>
                                                    {
                                                        stateDataShop.map((item, index) => <option key={index}>{item?.label}</option>)
                                                    }
                                                </select>
                                                <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height'>{err?.state ?? ''}</span>
                                            </div>
                                            <div className='col-6 col-sm-4 custom-over-hidden d-grid'>
                                                <label htmlFor="city">City</label>
                                                <select
                                                    value={storeData?.city}
                                                    name='city'
                                                    id='city'
                                                    className={`text-capitalize ${(err?.city) && 'border-red'}`}
                                                    autoComplete='off'
                                                    onChange={(e) => handleInputChange({ name: e.target.name, value: e.target.value })}
                                                >
                                                    <option value={0}>Select</option>
                                                    {
                                                        stateDataShop.filter(item => item.value === storeData?.state)[0]?.children.map((item, index) => {
                                                            return (<option key={index}>{item?.label}</option>)
                                                        })
                                                    }
                                                </select>
                                                <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height'>{err?.city ?? ''}</span>
                                            </div>
                                            <div className='col-12 col-sm-4 custom-over-hidden d-grid'>
                                                <label htmlFor="pinCode">Pincode</label>
                                                <input
                                                    type="number"
                                                    value={storeData?.pinCode}
                                                    name='pinCode'
                                                    id='pinCode'
                                                    className={(err?.pinCode) && 'border-red'}
                                                    autoComplete='off'
                                                    onChange={(e) => handleInputChange({
                                                        name: e.target.name,
                                                        value: e.target.value,
                                                        pattern: pattern.pinCode
                                                    })} />
                                                <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height'>{err?.pinCode ?? ''}</span>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6 d-flex gap-2'>
                                                <div className='d-grid' style={{ width: "calc(100% - 50px)" }}>
                                                    <label htmlFor="mobileNumber">Mobile</label>
                                                    <input
                                                        type="number"
                                                        value={storeData?.mobileNumber}
                                                        name='mobileNumber'
                                                        id='mobileNumber'
                                                        className={(err?.mobileNumber) && 'border-red'}
                                                        autoComplete='off'
                                                        onChange={(e) => handleInputChange({
                                                            name: e.target.name,
                                                            value: e.target.value,
                                                            pattern: pattern.mobile
                                                        })} />
                                                    <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height'>{err?.mobileNumber ?? ''}</span>
                                                </div>
                                                <div className='d-flex justify-content-around align-items-end mb-2' style={{ width: "50px" }}>
                                                    <label
                                                        className="switch"
                                                        data-tooltip-id="my-tooltip-show"
                                                        data-tooltip-content={`${storeData?.mobileNumberStatus == 1 ? "Hide" : "Show"} mobile number on snapstore`}
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            checked={storeData?.mobileNumberStatus == 1}
                                                            name='mobileNumberStatus'
                                                            onClick={(e) => handleInputChange({ name: e.target.name, value: e.target.checked ? 1 : 0 })}
                                                        />
                                                        <span className="slider"></span>
                                                    </label>
                                                    <Tooltip id="my-tooltip-show" place="top" />
                                                </div>
                                            </div>
                                            <div className='col-12 col-sm-6 d-flex gap-2'>
                                                <div className='d-grid' style={{ width: "calc(100% - 50px)" }}>
                                                    <label htmlFor="emailAddress">Email</label>
                                                    <input
                                                        type="email"
                                                        value={storeData?.emailAddress}
                                                        name='emailAddress'
                                                        id='emailAddress'
                                                        className={(err?.emailAddress) && 'border-red'}
                                                        autoComplete='off'
                                                        onChange={(e) => handleInputChange({
                                                            name: e.target.name,
                                                            value: e.target.value,
                                                            pattern: pattern.email
                                                        })} />
                                                    <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height'>{err?.emailAddress ?? ''}</span>
                                                </div>
                                                <div className='d-flex justify-content-around align-items-end mb-2' style={{ width: "50px" }}>
                                                    <label
                                                        className="switch"
                                                        data-tooltip-id="my-tooltip-email"
                                                        data-tooltip-content={`${storeData?.emailAddressStatus == 1 ? "Hide" : "Show"} email on snapstore`}
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            checked={storeData?.emailAddressStatus == 1}
                                                            name='emailAddressStatus'
                                                            onClick={(e) => handleInputChange({ name: e.target.name, value: e.target.checked ? 1 : 0 })}
                                                        />
                                                        <span className="slider"></span>
                                                    </label>
                                                    <Tooltip id="my-tooltip-email" place="top" />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                {/* Billing Address Details */}
                                <Row className='custom-contain-align-center py-3'>
                                    <h3 className='fw-semibold text-center py-3 py-sm-4'>Billing Address</h3>
                                    <Col className='d-none d-md-block col-md-3 custom-store-image'>
                                        {/* <SiReacthookform className='mt-4' /> */}
                                        <div className='logo-holder aspect-5-2 w-100'>
                                            <img src={require('../../Assets/Images/billing-address.png')} className='w-100 h-100 object-contain' alt="" />
                                        </div>
                                    </Col>
                                    <Col className='col-12 col-md-9 custom-store-detail-content d-grid gap-3'>
                                        <div className='row'>
                                            <div className='col-12 pb-3'>
                                                <div className='d-flex py-2 gap-2 align-items-center check-holder'>
                                                    <input type="checkbox" id='same' className='ui-checkbox' autoComplete='off' style={{ width: '20px', height: '20px', cursor: 'pointer' }}
                                                        onChange={handleAddressChange}
                                                        checked={isChecked}
                                                    />
                                                    <label htmlFor='same' className='fs-20 fw-semibold' style={{ cursor: 'pointer' }}>Same as communication address</label>
                                                </div>
                                            </div>
                                            <div className='col-12 col-sm-6 d-grid'>
                                                <label htmlFor="billingAddressFirstLine">Address First Line</label>
                                                <textarea
                                                    name="billingAddressFirstLine"
                                                    id="billingAddressFirstLine"
                                                    rows={2}
                                                    className={(err?.billingAddressFirstLine) && 'border-red'}
                                                    value={storeData?.billingAddressFirstLine}
                                                    autoComplete='off'
                                                    onChange={(e) => {
                                                        handleInputChange({ name: e.target.name, value: e.target.value });
                                                        manageSameAddress({ value: e.target.value, compareKey: 'addressFirstLine' })
                                                    }}
                                                    placeholder="Ex. 'Shop no., Building name'" ></textarea>
                                                <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height'>
                                                    {err?.billingAddressFirstLine ?? ''}
                                                </span>
                                            </div>
                                            <div className='col-12 col-sm-6 d-grid'>
                                                <label htmlFor="billingAddressSecondLine">Address Second Line</label>
                                                <textarea
                                                    name="billingAddressSecondLine"
                                                    id="billingAddressSecondLine"
                                                    rows={2}
                                                    className={(err?.billingAddressSecondLine) && 'border-red'}
                                                    value={storeData?.billingAddressSecondLine}
                                                    autoComplete='off'
                                                    onChange={(e) => {
                                                        handleInputChange({ name: e.target.name, value: e.target.value })
                                                        manageSameAddress({ value: e.target.value, compareKey: 'addressSecondLine' })
                                                    }}
                                                    placeholder="Ex. 'Landmark'"></textarea>
                                                <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height'>{err?.billingAddressSecondLine ?? ''}</span>
                                            </div>
                                        </div>
                                        <div className='row d-flex'>
                                            <div className='col-6 col-sm-4 custom-over-hidden d-grid'>
                                                <label htmlFor="billingState">State</label>
                                                <select
                                                    value={storeData?.billingState}
                                                    name='billingState'
                                                    id='billingState'
                                                    className={`text-capitalize ${(err?.billingState) && 'border-red'}`}
                                                    autoComplete='off'
                                                    onChange={(e) => {
                                                        handleInputChange({ name: e.target.name, value: e.target.value })
                                                        manageSameAddress({ value: e.target.value, compareKey: 'state' })
                                                    }}>
                                                    <option value={0}>Select</option>
                                                    {
                                                        stateData.map((item, index) => <option key={index}>{item?.label}</option>)
                                                    }
                                                </select>
                                                <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height'>{err?.billingState ?? ''}</span>
                                            </div>
                                            <div className='col-6 col-sm-4 custom-over-hidden d-grid'>
                                                <label htmlFor="billingCity">City</label>
                                                <select
                                                    name='billingCity'
                                                    id='billingCity'
                                                    className={`text-capitalize ${(err?.billingCity) && 'border-red'}`}
                                                    autoComplete='off'
                                                    onChange={(e) => {
                                                        handleInputChange({
                                                            name: e.target.name,
                                                            value: e.target.value
                                                        })
                                                        manageSameAddress({ value: e.target.value, compareKey: 'city' })
                                                    }}
                                                    value={storeData?.billingCity}
                                                >
                                                    <option value={0}>Select</option>
                                                    {
                                                        stateData.filter(item => item.value === storeData?.billingState)[0]?.children.map((item, index) => {
                                                            return (<option key={index}>{item?.label}</option>)
                                                        })
                                                    }
                                                </select>
                                                <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height'>{err?.billingCity ?? ''}</span>
                                            </div>
                                            <div className='col-12 col-sm-4 custom-over-hidden d-grid'>
                                                <label htmlFor="billingPinCode">Pincode</label>
                                                <input
                                                    type="number"
                                                    value={storeData?.billingPinCode}
                                                    name='billingPinCode'
                                                    id='billingPinCode'
                                                    className={(err?.billingPinCode) && 'border-red'}
                                                    autoComplete='off'
                                                    onChange={(e) => {
                                                        handleInputChange({
                                                            name: e.target.name,
                                                            value: e.target.value,
                                                            pattern: pattern.pinCode
                                                        })
                                                        manageSameAddress({ value: e.target.value, compareKey: 'pinCode' })
                                                    }} />
                                                <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height'>{err?.billingPinCode ?? ''}</span>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6 d-grid'>
                                                <label htmlFor="billingMobileNumber">Mobile</label>
                                                <input
                                                    type="number"
                                                    value={storeData?.billingMobileNumber}
                                                    name='billingMobileNumber'
                                                    id='billingMobileNumber'
                                                    className={(err?.billingMobileNumber) && 'border-red'}
                                                    autoComplete='off'
                                                    onChange={(e) => {
                                                        handleInputChange({
                                                            name: e.target.name,
                                                            value: e.target.value,
                                                            pattern: pattern.mobile
                                                        })
                                                        manageSameAddress({ value: e.target.value, compareKey: 'mobileNumber' })
                                                    }} />
                                                <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height'>{err?.billingMobileNumber ?? ''}</span>
                                            </div>
                                            <div className='col-12 col-sm-6 d-grid'>
                                                <label htmlFor="billingEmailAddress">Billing Email</label>
                                                <input
                                                    type="email"
                                                    value={storeData?.billingEmailAddress}
                                                    name='billingEmailAddress'
                                                    id='billingEmailAddress'
                                                    className={(err?.billingEmailAddress) && 'border-red'}
                                                    autoComplete='off'
                                                    onChange={(e) => {
                                                        handleInputChange({
                                                            name: e.target.name,
                                                            value: e.target.value,
                                                            pattern: pattern.email
                                                        })
                                                        manageSameAddress({ value: e.target.value, compareKey: 'emailAddress' })
                                                    }} />
                                                <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height'>{err?.billingEmailAddress ?? ''}</span>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                {/* Save button */}
                                <div className='d-flex justify-content-center pt-3 pt-md-4'>
                                    <Button
                                        className={`next-button text-decoration-none fw-bold d-grid p-0`}
                                        onClick={() => {
                                            submitStoreData();
                                        }}

                                        style={{ border: "0px", background: "transparent", pointerEvents: (err) && (Object.values(err)?.length !== 0) ? 'none' : 'auto', opacity: (err) && (Object.values(err)?.length !== 0) ? '.1' : '1' }}
                                    >
                                        <button
                                            type="button"
                                            className={`button active-btn ${(loader?.store ? "final-active-btn" : '')}`}
                                            disabled={loader?.store}
                                        >
                                            <span className="button__text">Save</span>
                                            <span className="button__icon">
                                                <MdKeyboardDoubleArrowRight className="svg" />
                                            </span>
                                        </button>
                                    </Button>
                                </div>
                            </Container>
                        </Tab>
                        {
                            storeData?.storeType == "SNAPSTORE" &&
                            <Tab eventKey="changeTheme" title="Change Theme" className='p-2 p-sm-3'>
                                <div className='d-grid gap-2'>
                                    <div className='d-grid gap-2 px-2'>
                                        <h4 className='fw-semibold m-0'>
                                            Select theme
                                        </h4>
                                        <p className='fs-sm-12 fs-lg-14 m-0 p-color'>Personalize your store's look. Select a theme, implement it, and switch it whenever you wish.</p>
                                    </div>
                                    <Container className='py-4'>
                                        <Row className='gy-4 position-relative'>
                                            {
                                                loader?.theme &&
                                                <div className='position-absolute w-100 h-100'>
                                                    <div
                                                        className='position-sticky top-0 start-0 w-100 flex-center-align'
                                                        style={{
                                                            backgroundColor: 'rgba(255,255,255,0.85)',
                                                            zIndex: "9999",
                                                            height: "100vh"
                                                        }}
                                                    >
                                                        <div className='d-grid text-center text-success gap-1'>
                                                            <div className='w-100 aspect-2-1 aspect-md-2-1'>
                                                                <Loader />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                themeOption.map((item, index) => {
                                                    return (
                                                        <Col
                                                            key={index}
                                                            className={'col-12 col-sm-6 col-lg-4 d-grid'}
                                                            onClick={() => setThemeValue(item.value)}
                                                        >
                                                            <div className={`
                                                            custom-img-holder 
                                                            ${(themeValue === item.value) && 'custom-img-holder-active'}`}>
                                                                <img src={item.image} alt="" />
                                                                <span className={`custom-span ${(themeValue === item.value) && 'custom-span-active'}`}>
                                                                    <i className={`bi bi-check-lg 
                                                                    ${(themeValue !== item.value) && 'd-none'}`}></i>
                                                                </span>
                                                            </div>
                                                        </Col>
                                                    )
                                                })
                                            }
                                        </Row>
                                        <div className='d-flex justify-content-center pt-3 pt-md-4'>
                                            <Link
                                                className={`next-button text-decoration-none fw-bold d-grid`}
                                                onClick={submitThemeData}>
                                                <button type="button" className={`button active-btn`}>
                                                    <span className="button__text">Save</span>
                                                    <span className="button__icon">
                                                        <MdKeyboardDoubleArrowRight className="svg" />
                                                    </span>
                                                </button>
                                            </Link>
                                        </div>
                                    </Container>
                                </div>
                            </Tab>
                        }
                        {
                            storeData?.storeType == "SNAPSTORE" &&
                            <Tab eventKey="manageMedia" title="Social Media" className='p-2 p-sm-3'>
                                <div className='d-grid gap-3'>
                                    {/* Top Content */}
                                    <div className='d-grid gap-2 px-2'>
                                        <h4 className='fw-semibold m-0'>
                                            Manage Social Media
                                        </h4>
                                        <p className='fs-sm-12 fs-lg-14 m-0 p-color'>Connect your social media accounts for your customers to find you easily.</p>
                                    </div>
                                    <Container className='px-sm-0 py-sm-4'>
                                        <Row className='gy-4'>
                                            {/* <Col className='col-12 col-md-5 d-grid py-3 custom-border-box'> */}
                                            <Col className='col-12 col-md-5 px-0'>
                                                <div className='custom-border-box p-3'>
                                                    <FormComponent
                                                        action={frontEnd_API.snapStore}
                                                        valueData={(updateSocialLink) && updateSocialLink}
                                                        actionType={`${(updateSocialLink) ? 'put' : 'post'}`}
                                                        actionValue={(updateSocialLink) && updateSocialLinkId}
                                                        submitFullWidth={false}
                                                        onSubmit={(e) => callSocialList(e)}
                                                        formMessage={(updateSocialLink) ? "Link Updated in store !" : "Link Added to store !"}
                                                        fields={[
                                                            {
                                                                type: 'select',
                                                                options: socialLinks,
                                                                divClass: 'col-12',
                                                                name: 'icon',
                                                                key: 'icon',
                                                                required: true,
                                                            },
                                                            {
                                                                divClass: 'col-12',
                                                                name: 'link',
                                                                key: 'link',
                                                                required: true,
                                                                pattern: pattern.link,
                                                                placeholder: "Enter link"
                                                            },
                                                        ]}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className='col-12 col-md-7 d-grid px-0 px-sm-2 align-content-start'>
                                                {
                                                    (listData.length === 0) ?
                                                        <h4 className='text-center p-color'>No Links Added</h4>
                                                        : <table className='cs-table'>
                                                            <thead>
                                                                <tr className='fs-sm-12'>
                                                                    <th className='text-center'>Media</th>
                                                                    <th className='text-center'>Link</th>
                                                                    <th className='text-center'>Status</th>
                                                                    <th className='text-center'>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    loader?.social ?
                                                                        <tr>
                                                                            <td colSpan={4}>
                                                                                <div className='w-100 aspect-2-1 aspect-md-2-1'>
                                                                                    <Loader />
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        : (listData?.length > 0) &&
                                                                        listData?.map((item, index) => {
                                                                            return (

                                                                                <tr key={index}>
                                                                                    <td className='text-center'>
                                                                                        <i className={`fs-20 bi bi-${item.icon}`}></i>
                                                                                    </td>
                                                                                    <td className='fs-14 fs-sm-10'>
                                                                                        <Link className='d-block d-sm-none' to={item.link} target='_black'>
                                                                                            {
                                                                                                (item.link.length > 15)
                                                                                                    ? item.link.substring(0, 15)
                                                                                                    + '...'
                                                                                                    : item.link
                                                                                            }
                                                                                        </Link>
                                                                                        <Link className='d-none d-sm-block' to={item.link} target='_black'>
                                                                                            {
                                                                                                (item.link.length > 30)
                                                                                                    ? item.link.substring(0, 30)
                                                                                                    + '...'
                                                                                                    : item.link
                                                                                            }
                                                                                        </Link>
                                                                                    </td>
                                                                                    <td className='text-center'>
                                                                                        <label className="switch">
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                checked={(item.status === 1) ? true : false}
                                                                                                value={item.status}
                                                                                                onChange={() => handleLinkStatus(item)}
                                                                                            />
                                                                                            <span className="slider"></span>
                                                                                        </label>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className='flex-between-align custom-btns'>
                                                                                            <i className="fs-14 custom-btns-edit bi bi-pen-fill"
                                                                                                onClick={() => handleUpdateLink(item)} />
                                                                                            <i className="fs-14 custom-btns-delete bi bi-trash-fill" onClick={() => deleteLink(item)} />
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                }
                                                            </tbody>
                                                        </table>
                                                }

                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Tab>
                        }
                        {
                            storeData?.storeType == "SNAPSTORE" &&
                            <Tab eventKey="coupon" title="Coupon" className='p-2 p-sm-3'>
                                <div className='d-grid gap-3'>
                                    {/* Top Content */}
                                    <div className='d-grid gap-2 px-2'>
                                        <h4 className='fw-semibold m-0'>
                                            Store Coupon
                                        </h4>
                                        <p className='fs-sm-12 fs-lg-14 m-0 p-color'>Manage coupons for your snap store.</p>
                                    </div>
                                    <Container className='px-sm-0 py-sm-4'>
                                        <Row className='gy-4'>
                                            <Col className='col-12 col-md-8 d-grid py-3 custom-border-box'>
                                                <FormComponent
                                                    action={frontEnd_API.coupon}
                                                    valueData={(updateCoupon) && updateCoupon}
                                                    actionType={`${(updateCoupon) ? 'put' : 'post'}`}
                                                    actionValue={(updateCoupon) && updateCouponId}
                                                    submitFullWidth={false}
                                                    onSubmit={(e) => callCouponList(e)}
                                                    formMessage={(updateCoupon) ? "Coupon Updated in store !" : "Coupon Added to store !"}
                                                    fields={[
                                                        {
                                                            divClass: 'col-12 col-md-6',
                                                            name: 'coupon name',
                                                            key: 'name',
                                                            required: true,
                                                            pattern: pattern.textNumber,
                                                            placeholder: "Enter coupon name"
                                                        },
                                                        {
                                                            type: 'select',
                                                            options: [
                                                                {
                                                                    value: 'PER',
                                                                    label: 'percentage'
                                                                },
                                                                {
                                                                    value: 'AMT',
                                                                    label: 'amount'
                                                                }
                                                            ],
                                                            divClass: 'col-12 col-md-6',
                                                            name: 'type',
                                                            key: 'type',
                                                            required: true,
                                                        },
                                                        {
                                                            divClass: 'col-12 col-md-6',
                                                            name: 'value',
                                                            key: 'value',
                                                            required: true,
                                                            placeholder: "Enter amount or percentager value"
                                                        },
                                                        {
                                                            type: 'date',
                                                            divClass: 'col-12 col-md-6',
                                                            name: 'expire Date',
                                                            key: 'expireDate',
                                                            required: true,
                                                        },
                                                        {
                                                            divClass: 'col-12 col-md-6',
                                                            name: 'limit Condition',
                                                            key: 'limitCondition',
                                                            required: true,
                                                            placeholder: "Enter limit condition"
                                                        },
                                                        {
                                                            divClass: 'col-12 col-md-6',
                                                            name: 'max Off Amount',
                                                            key: 'maxOffAmount',
                                                            required: true,
                                                            placeholder: "Enter maximum discount in amount"
                                                        },
                                                        {
                                                            divClass: 'col-12 col-md-6',
                                                            name: 'user limit',
                                                            key: 'userLimit',
                                                            required: true,
                                                        },
                                                        {
                                                            divClass: 'col-12 col-md-6',
                                                            name: 'coupon limit',
                                                            key: 'couponLimit',
                                                            required: true,
                                                        },
                                                    ]}
                                                />
                                            </Col>
                                            <Col className='col-12 d-grid px-0 px-sm-2 align-content-start'>
                                                {
                                                    (couponListData.length === 0) ?
                                                        <h4 className='text-center p-color'>No Coupon Added Yet!</h4>
                                                        : <table className='cs-table'>
                                                            <thead>
                                                                <tr className='fs-sm-12 text-center text-capitalize'>
                                                                    <th>Name</th>
                                                                    <th>value</th>
                                                                    <th>Type</th>
                                                                    <th>Expire On</th>
                                                                    <th>limit</th>
                                                                    <th>max Off</th>
                                                                    <th>user limit</th>
                                                                    <th>coupon limit</th>
                                                                    <th>total used</th>
                                                                    <th>Status</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    loader?.coupon ?
                                                                        <tr>
                                                                            <td colSpan={11}>
                                                                                <div className='w-100 aspect-4-1 aspect-md-2-1'>
                                                                                    <Loader />
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        :
                                                                        (couponListData?.length > 0) &&
                                                                        couponListData?.map((item, index) => {
                                                                            return (
                                                                                <tr key={index}>
                                                                                    <td className='fs-14 fs-sm-10'>{item?.name}</td>
                                                                                    <td className='fs-14 fs-sm-10'>{item?.value}</td>
                                                                                    <td className='fs-14 fs-sm-10'>{item?.type}</td>
                                                                                    <td className='fs-14 fs-sm-10'>{moment(item?.expireDate)?.format('DD, MMM YYYY')}</td>
                                                                                    <td className='fs-14 fs-sm-10'>{item?.limitCondition}</td>
                                                                                    <td className='fs-14 fs-sm-10'>{item?.maxOffAmount}</td>
                                                                                    <td className='fs-14 fs-sm-10'>{item?.userLimit}</td>
                                                                                    <td className='fs-14 fs-sm-10'>{item?.couponLimit}</td>
                                                                                    <td className='fs-14 fs-sm-10'>{item?.totalUsed}</td>
                                                                                    <td className='text-center'>
                                                                                        <label className="switch">
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                checked={(item.status === 1) ? true : false}
                                                                                                value={item.status}
                                                                                                onChange={() => handleCouponStatus(item)}
                                                                                            />
                                                                                            <span className="slider"></span>
                                                                                        </label>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className='flex-between-align custom-btns'>
                                                                                            <i className="fs-14 custom-btns-edit bi bi-pen-fill"
                                                                                                onClick={() => handleUpdateCoupon(item)} />
                                                                                            <i className="fs-14 custom-btns-delete bi bi-trash-fill" onClick={() => deleteCoupon(item)} />
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                }
                                                            </tbody>
                                                        </table>
                                                }

                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Tab>
                        }
                        {
                            storeData?.storeType == "SNAPSTORE" &&
                            <Tab eventKey="banner" title="Banner" className='p-2 p-sm-3'>
                                <div className='d-grid gap-3'>
                                    {/* Top Content */}
                                    <div className='d-grid gap-2 px-2'>
                                        <h4 className='fw-semibold m-0'>
                                            Manage Store Banner
                                        </h4>
                                        <div className='d-grid gap-1 fs-14 fs-sm-10 fs-lg-12'>
                                            <p className='p-color m-0'>
                                                Add a stunning banner to your homepage to captivate visitors and showcase your brand instantly.
                                            </p>
                                            <p className='p-color m-0'>
                                                <i class="bi bi-info-circle-fill"></i> Upload an image with at least 1800x700 resolution or a 9:3.5 aspect ratio for the best look.
                                            </p>
                                        </div>
                                    </div>
                                    <Container className='px-sm-0 py-sm-4'>
                                        <Row className='gy-4'>
                                            <Col className='col-12 col-md-5 d-grid py-3 custom-border-box'>
                                                <FormComponent
                                                    action={frontEnd_API.banner}
                                                    valueData={(updateBanner) ? updateBanner : { relatedName: "category" }}
                                                    actionType={`${(updateBanner) ? 'put' : 'post'}`}
                                                    actionValue={(updateBanner) && updateBannerId}
                                                    submitFullWidth={false}
                                                    onSubmit={(e) => callBannerList(e)}
                                                    formMessage={(updateBanner) ? "Banner Updated in store !" : "Banner Added to store !"}
                                                    fields={[
                                                        {
                                                            type: 'select',
                                                            options: storeCategoryData,
                                                            divClass: 'col-12',
                                                            label: 'related',
                                                            name: 'relatedId',
                                                            key: 'category',
                                                            showData: '',
                                                        },
                                                        {
                                                            divClass: 'col-12',
                                                            type: 'file',
                                                            name: 'image',
                                                            key: 'thumbnail',
                                                            required: parseInt(updateBannerId) <= 0,
                                                        },
                                                        {
                                                            divClass: 'col-12',
                                                            name: 'banner name',
                                                            key: 'name',
                                                            required: true,
                                                            placeholder: "Enter banner name",
                                                            pattern: pattern?.textNumWithSpace
                                                        },
                                                    ]}
                                                />
                                            </Col>
                                            <Col className='col-12 col-md-7 d-grid px-0 px-sm-2 align-content-start'>
                                                {
                                                    (bannerList.length === 0) ?
                                                        <h4 className='text-center p-color'>No Banner Added</h4>
                                                        : <table className='cs-table'>
                                                            <thead>
                                                                <tr className='fs-sm-12'>
                                                                    <th className='text-center'>Name</th>
                                                                    <th className='text-center'>Image</th>
                                                                    <th className='text-center'>Status</th>
                                                                    <th className='text-center'>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    loader?.banner ?
                                                                        <tr>
                                                                            <td colSpan={4}>
                                                                                <div className='w-100 aspect-3-1 aspect-md-2-1'>
                                                                                    <Loader />
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        :
                                                                        (bannerList?.length > 0) &&
                                                                        bannerList?.map((item, index) => {
                                                                            return (
                                                                                <tr key={index}>
                                                                                    <td className='text-center text-capitalize'>
                                                                                        {item?.name}
                                                                                    </td>
                                                                                    <td className='text-center flex-center-align'>
                                                                                        <div className='d-grid align-content-center' style={{
                                                                                            height: "100px",
                                                                                            width: "150px",
                                                                                            overflow: "hidden",
                                                                                            borderRadius: ".5rem"
                                                                                        }}>
                                                                                            <img src={item?.thumbnail}
                                                                                                className='w-100'
                                                                                                style={{
                                                                                                    height: "auto",
                                                                                                    objectFit: "contain"
                                                                                                }}
                                                                                                alt="" />
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className='text-center'>
                                                                                        <label className="switch">
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                checked={(item.status === 1) ? true : false}
                                                                                                value={item.status}
                                                                                                onChange={() => handleBannerStatus(item)}
                                                                                            />
                                                                                            <span className="slider"></span>
                                                                                        </label>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className='flex-between-align custom-btns'>
                                                                                            <i className="fs-14 custom-btns-edit bi bi-pen-fill"
                                                                                                onClick={() => handleUpdateBanner(item)} />
                                                                                            <i className="fs-14 custom-btns-delete bi bi-trash-fill" onClick={() => deleteBanner(item)} />
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                }
                                                            </tbody>
                                                        </table>
                                                }

                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Tab>
                        }

                        <Tab eventKey="returnDetails" title="Return Details" className='p-2 p-sm-3'>
                            <div className='d-grid gap-2'>
                                <div className='d-grid gap-2 px-2'>
                                    <div className='d-flex justify-content-between'>
                                        <h4 className='fw-semibold m-0'>
                                            {/* Return Product Handling */}
                                            Customer Returns on Snapstore
                                        </h4>
                                        <div className='seller-return-setting'>
                                            <label className="switch">
                                                <input
                                                    type="checkbox"
                                                    checked={returnToStore == 1}
                                                    onClick={(e) => manageStoreReturnOrderStatus(e?.target?.checked)}
                                                />
                                                <span className="slider"></span>
                                            </label>
                                        </div>
                                    </div>
                                    {/* <p>{`Do you want to allow your customers to create return requests directly from the Snapstore? If a customer initiates a return request, the order will be shipped back to your shop address and will be available for re-shipping in the Returns > RTO section.`}</p> */}
                                    <p>{`Do you want to allow your customers to create return requests directly from the Snapstore? If a customer initiates a return request, the order will be shipped back to your shop address and will be available for re-shipping in the Returns section.`}</p>
                                    <Tooltip id="my-shop" place="right" />
                                </div>
                            </div>
                        </Tab>

                        {
                            storeData?.storeType == "SNAPSTORE" &&
                            <Tab
                                eventKey="customDomain"
                                // disabled
                                disabled={sellerDetail?.expired}
                                title={(
                                    <div className='position-relative'>
                                        <span>Custom Domain</span>
                                        <span
                                            className='position-absolute bg-warning text-dark fs-10 rounded-1 px-2'
                                            style={{ top: '-15px', right: '-25px' }}
                                        >Premium</span>
                                    </div>)}
                                className='p-2 p-sm-3'
                            // className='p-2 p-sm-3 bg-body-secondary'
                            >
                                <Row>
                                    <Col xxl='5' lg='5' md='7' xs='12'>
                                        <div className='d-flex flex-column'>
                                            <h5 className='fw-semibold m-0'>{storeData?.customDomainStatus == 1 ? 'Your activated domain' : 'Add your custom domain'}</h5>
                                            <div className='seller-return-setting mt-1'>
                                                <div className='d-flex gap-2'>
                                                    <Input
                                                        className='w-100'
                                                        placeholder='enter your custom domain here'
                                                        value={customDomain ?? ''}
                                                        disabled={storeData?.customDomainStatus == 1}
                                                        onChange={(e) => {
                                                            setCustomDomainError('')
                                                            setCustomDomain(e?.target?.value)
                                                        }}
                                                    />
                                                    {/*
                                                {
                                                    activeStore?.customDomain?.length > 0 && (
                                                        <div
                                                            className='bg-danger text-white px-2 py-2 rounded-2 pointer'
                                                            onClick={() => {
                                                                Swal.fire({
                                                                    title: `Are you sure to remove custom domain?`,
                                                                    icon: 'warning',
                                                                    showCancelButton: true,
                                                                    confirmButtonColor: '#04ac84',
                                                                    cancelButtonColor: '#d33',
                                                                    confirmButtonText: 'Yes',
                                                                    cancelButtonText: 'No',
                                                                }).then(async (result) => {
                                                                    if (result.isConfirmed) {
                                                                        setCustomDomain('')
                                                                        manageCustomDomainDetail(-1)
                                                                    }
                                                                })
                                                            }}
                                                        >
                                                            <i className='bi bi-x-lg'></i>
                                                        </div>
                                                    )
                                                }
                                                */}
                                                </div>
                                                {customDomainError && (<span className='text-danger'>{customDomainError}</span>)}
                                                {/* {storeData?.customDomainStatus == 1 && (<span className='text-success fw-medium'>Domain connected with snap store successfully</span>)} */}
                                            </div>
                                            {
                                                storeData?.customDomainStatus <= 0 && (
                                                    <div className='seller-return-setting'>
                                                        {
                                                            domainFormSubmitStatus ? (
                                                                <Button className='bg-success border-0 mt-3' disabled >Please wait</Button>
                                                            ) : (
                                                                <Button className='bg-success border-0 mt-3' onClick={() => manageCustomDomainDetail()} >Submit</Button>
                                                            )
                                                        }
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl='12' xs='12'>
                                        {
                                            storeData?.customDomainStatus != 1 ? (
                                                <div>
                                                    <CustomDomainSteps domain={activeStore?.customDomain ?? 'example.com'} ipAddress='152.42.158.37' />
                                                </div>
                                            ) :
                                                <div className='my-4'>
                                                    <Row>
                                                        <Col className='col-12 flex-center-align'>
                                                            <div style={{ width: "100%", maxWidth: "150px", aspectRatio: "1/1" }}>
                                                                <img src={require('../../Assets/Gif/Animation - 1736332079997.gif')} alt="Description of the GIF" style={{
                                                                    objectFit: "contain",
                                                                    width: "100%",
                                                                    height: "100%"
                                                                }} />
                                                            </div>
                                                        </Col>
                                                        <Col className='col-12 d-grid text-center align-content-center'>
                                                            <h2 className='text-success fw-bold'>Domain connected successfully !</h2>
                                                        </Col>
                                                    </Row>
                                                </div>
                                        }
                                    </Col>
                                </Row>
                            </Tab>
                        }
                    </Tabs>
                </div>
            </Container>
            <Modal
                show={show}
                // show={true}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col className='col-12 flex-center-align'>
                                <div style={{ width: "300px", aspectRatio: "4/2" }}>
                                    <img src={require('../../Assets/Gif/Animation - 1736332079997.gif')} alt="Description of the GIF" style={{
                                        objectFit: "contain",
                                        width: "100%",
                                        height: "100%"
                                    }} />
                                </div>
                            </Col>
                            <Col className='col-12 d-grid text-center'>
                                <h2 className='text-success fw-bold'>Domain connected successfully !</h2>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
            <ToastContainer />
        </div >
    )
}

export default SellerStoreSetting